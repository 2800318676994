import { isGuestUserSession } from "@aspire/common";
import { Box, CircularProgress, Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api, apiHooks, logout } from "~/api.js";
import {
  Button,
  FormTitle,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";

export type VisionIncidentPreviewPageProps = {};

export function VisionIncidentPreviewPage(
  props: VisionIncidentPreviewPageProps,
) {
  const userContext = React.useContext(LoggedInUserContext);
  const [submitting, setSubmitting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const incidentId = searchParams.get("incident-id");

  useEffect(() => {
    if (!incidentId) {
      renderErrorToast({
        message:
          "Incident ID not found. Please try to login through share code/email link again",
      });
      navigate(routeFns.home());
    }
  }, []);

  const [{ loading, data: incidentPreviewData }] =
    apiHooks.vision.previewIncident(incidentId ?? "");

  // if we have already accepted then redirect to the form context page
  useEffect(() => {
    if (incidentPreviewData && "formContextId" in incidentPreviewData) {
      navigate(
        routeFns.formContextPage(
          incidentPreviewData.formContextId,
          incidentPreviewData.patientId,
        ),
        { replace: true },
      );
    }
  }, [incidentPreviewData]);

  return loading ||
    !incidentPreviewData ||
    !("patientDetails" in incidentPreviewData) ? (
    <CircularProgress />
  ) : (
    <PopupDialog open title="Incident Preview" fullWidth>
      <Stack sx={{ margin: "0.5rem", display: "flex" }}>
        <FormTitle
          useReducedTopPadding={true}
          hasTitleBottomMargin={false}
          titleText={`Confirm access to Section 136 Form`}
        />
        Office {incidentPreviewData.officerDetails.name} has shared a 136 form
        with you.
        <Box
          sx={{ mt: "2rem", display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={async () => {
              setSubmitting(true);
              try {
                const res = await api.vision.rejectIncident(
                  incidentId ?? "",
                  "Received in Error",
                );
                if (res.status !== 204) {
                  renderErrorToast({ message: "Failed to reject incident" });
                } else {
                  renderSuccessToast({ message: "Incident rejected" });
                  setTimeout(() => {
                    if (isGuestUserSession(userContext?.user)) {
                      logout();
                    }
                    navigate(routeFns.home());
                  }, 2000);
                }
              } catch (e) {
                renderErrorToast({ message: "Failed to reject incident" });
              } finally {
                setSubmitting(false);
              }
            }}
            label="Received in Error"
            color="error"
          />
          <Button
            onClick={async () => {
              setSubmitting(true);
              try {
                const res = await api.vision.acceptIncident(incidentId ?? "");
                if (res.status !== 200) {
                  renderErrorToast({ message: "Failed to accept incident" });
                }

                // navigate to fpp
                navigate(
                  routeFns.formContextPage(
                    res.data.formContextId,
                    res.data.patientId,
                  ),
                  { replace: true },
                );
              } catch (e) {
                renderErrorToast({ message: "Failed to accept incident" });
              } finally {
                setSubmitting(false);
              }
            }}
            label="Confirm"
          />
        </Box>
      </Stack>
    </PopupDialog>
  );
}
