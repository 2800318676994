export default {
  hooks: {
    externalPatientLink: {
      pullEvent: {
        failedRio:
          "Synchronisation was unsuccessful, failed to connect to Rio.",
        failedPds:
          "Synchronisation was unsuccessful, failed to connect to PDS.",
        succeeded: {
          updateFailed: "Synchronisation was unsuccessful.",
          updateSkipped:
            "Patient details synchronised, available updates were ignored/skipped.",
          updateNotRequired:
            "Synchronisation was successful, information is up to date.",
          updateSucceeded: "Synchronisation was successful.",
        },
      },
    },
  },
};
