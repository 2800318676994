import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Choice, SystemType, doAllValuesMatch } from "./helpers.js";

export type ChoiceGroupProps = {
  label: string;
  choices: Choice[];
  chosen: SystemType | undefined;
  setChosen: (system: SystemType) => void;
};

export type LayoutProps = {
  choiceGroups: ChoiceGroupProps[];
  systemTypeMap: Record<SystemType, string>;
};

export const ChoiceLayoutMobile = ({
  choiceGroups,
  systemTypeMap,
}: LayoutProps) => {
  return (
    <Stack gap="1rem">
      {choiceGroups.map((choiceGroup) => (
        <ChoiceGroupBase
          key={choiceGroup.label}
          label={choiceGroup.label}
          direction="column"
          embedLabels={true}
          choices={choiceGroup.choices}
          chosen={choiceGroup.chosen}
          setChosen={choiceGroup.setChosen}
          systemTypeMap={systemTypeMap}
        />
      ))}
    </Stack>
  );
};

export const ChoiceLayoutDesktop = ({
  choiceGroups,
  systemTypeMap,
}: LayoutProps) => {
  // Get the list of systems. We grab it from the first item, as it should
  // be present on all groups, even if the value is undefined.
  const systems = choiceGroups[0].choices.map((choice) => choice.system);

  // Get a list of field names.
  const fields = choiceGroups.map((choiceGroup) => choiceGroup.label);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `0fr repeat(${systems.length} 1fr)`,
        gap: "0.5rem",
      }}
    >
      {systems.map((system, index) => (
        // Systems are the column headers
        <Typography
          key={system}
          sx={{
            fontWeight: "bold",
            justifySelf: "center",
            userSelect: "none",
            gridRow: 1,
            gridColumn: index + 2,
          }}
        >
          {systemTypeMap[system]}
        </Typography>
      ))}

      {fields.map((field, index) => (
        // Fields are the row headers
        <Typography
          key={field}
          sx={{
            fontWeight: "bold",
            justifySelf: "right",
            textAlign: "right",
            alignContent: "center",
            userSelect: "none",
            gridRow: index + 2,
            gridColumn: 1,
          }}
        >
          {field}
        </Typography>
      ))}

      {choiceGroups.map((choiceGroup, index) => (
        // Radio buttons span the remaining columns
        <Box
          key={choiceGroup.label}
          sx={{
            gridRow: index + 2,
            gridColumn: `2 / ${systems.length + 2}`,
          }}
        >
          <ChoiceGroupBase
            label={choiceGroup.label}
            direction="row"
            embedLabels={false}
            choices={choiceGroup.choices}
            chosen={choiceGroup.chosen}
            setChosen={choiceGroup.setChosen}
            systemTypeMap={systemTypeMap}
          />
        </Box>
      ))}
    </Box>
  );
};

type ChoiceGroupBaseProps = ChoiceGroupProps & {
  direction: "row" | "column";
  embedLabels: boolean;
  systemTypeMap: Record<SystemType, string>;
};

const ChoiceGroupBase = ({
  label,
  choices,
  chosen,
  setChosen,
  direction,
  embedLabels,
  systemTypeMap,
}: ChoiceGroupBaseProps) => {
  const theme = useTheme();

  const backgroundUnselected = theme.palette.warning.light;
  const backgroundSelected = theme.palette.action.disabledBackground;
  const foregroundUnselected = theme.palette.background.paper;
  const foregroundSelected = theme.palette.primary.main;
  const textSelected = theme.palette.text.primary;
  const textUnselected = theme.palette.primary.contrastText;

  const allChoicesMatch = doAllValuesMatch(choices);

  return (
    <Box>
      {embedLabels && (
        <Typography sx={{ fontWeight: "bold", userSelect: "none" }}>
          {label}
        </Typography>
      )}
      <RadioGroup
        sx={{
          backgroundColor:
            allChoicesMatch || chosen !== undefined
              ? backgroundSelected
              : backgroundUnselected,
        }}
        value={chosen}
        onChange={(event) => {
          setChosen(event.target.value as SystemType);
        }}
      >
        <Stack
          direction={direction}
          gap={"0.5rem"}
          sx={{ padding: "0.5rem", flexGrow: 1, minHeight: "3rem" }}
        >
          {choices.map((choice) => {
            const backgroundColor = allChoicesMatch
              ? "transparent"
              : chosen === choice.system
                ? foregroundSelected
                : foregroundUnselected;

            const textColor = allChoicesMatch
              ? textSelected
              : chosen === choice.system
                ? textUnselected
                : textSelected;

            const borderColor = allChoicesMatch ? "lightgray" : "black";

            return (
              <Stack
                key={choice.system}
                gap={"1rem"}
                direction={"row"}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexBasis: 0,
                  height: "1fr",
                  borderStyle: "solid",
                  borderColor: borderColor,
                  borderWidth: "1px",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  backgroundColor: backgroundColor,
                }}
              >
                {allChoicesMatch ? (
                  <ChoiceDisplay {...choice} />
                ) : (
                  <ChoiceInput color={textColor} {...choice} />
                )}
                {embedLabels && (
                  <Typography
                    sx={{
                      textAlign: "right",
                      alignContent: "center",
                      minHeight: "3rem",
                      color: textColor,
                      userSelect: "none",
                    }}
                  >
                    {systemTypeMap[choice.system]}
                  </Typography>
                )}
              </Stack>
            );
          })}
        </Stack>
      </RadioGroup>
    </Box>
  );
};

/**
 * A component to allow the user to make a choice
 */
const ChoiceInput = ({
  system: value,
  type: type,
  value: label,
  color,
}: Choice & { color: string }) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          data-testid={`choice-${type.toLowerCase()}-${value.toLowerCase()}`}
          sx={{
            display: "flex",
            color: "black",
            "&.Mui-checked": {
              color: "white",
            },
          }}
        />
      }
      label={label}
      sx={{
        color: color,
        flexGrow: 1,
        minHeight: "max-content",
        userSelect: "none",
      }}
    />
  );
};

/**
 * A component to display the obvious choice
 */
const ChoiceDisplay = ({ value }: Pick<Choice, "value">) => {
  const theme = useTheme();
  const foregroundSelected = theme.palette.primary.main;

  return (
    <Box
      sx={{
        display: "flex",
        paddingLeft: "1rem",
        marginTop: "0.75rem",
        marginBottom: "0.75rem",
        flexGrow: 1,
        borderLeft: value ? "0.25rem solid" : "none",
        borderColor: foregroundSelected,
      }}
    >
      {value && (
        <Typography
          sx={{
            textAlign: "left",
            alignContent: "center",
            userSelect: "none",
          }}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};
