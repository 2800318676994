export default {
  forms: {
    amhpLocalAuthorityFormField: {
      typeaheadPlaceholder: "Please select the correct option",
      enterLaActingOnBehalfOf:
        "Enter the name of the local service authority you are acting on behalf of",
      enterLaWhoApprovedYou:
        "Enter the name of the local service authority who approved you",
      areYouActingOnBehalfOfLaWhoApprovedYou:
        "Are you acting on behalf of the same local service authority that approved you?",
    },
    hospitalPicker: {
      typeaheadPlaceholder: "Enter hospital name",
      addressCheckBoxLabel: "Please confirm the name and address is correct",
      addressPlaceholder: "Enter hospital address",
      postalCodePlaceholder: "Enter postcode",
      addressLabel: "Hospital address",
      postalLabel: "Hospital Postcode",
    },
    nameAddressFormField: {
      addressCheckBoxLabel: "Please confirm the address is correct",
    },
    completionConfirmationMessageWithNoteRestriction:
      "You are about to mark administrative scrutiny for this assessment as complete. Please note that you cannot add a file note to this form once you confirm.",
    completionConfirmationMessageWithRestrictions:
      "You are about to mark administrative scrutiny for this assessment as complete. Please note that you cannot add a file note or send a section 15 amend request for this assessment once you confirm.",
    ukDateTimeWarning: "Please enter date and time in UK local time",
    ukTimeWarning: "Please enter time in UK local time",
    approvedClinicianWarning:
      "You are not recorded as an approved clinician on your eMHA profile.\nA responsible clinician is an approved clinician with overall responsibility for the patient and involves specialist training.\nYou should only continue if you are an approved clinician and the responsible clinician for this patient.",
  },
};
