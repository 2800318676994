import { PatientIndexSearchResult } from "@aspire/common";
import { Box, Stack, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormTitle,
  PatientCardExtended,
} from "~/components/design-system/index.js";
import { PatientSearchResultBanner } from "./SearchResultBanners.js";

export function PatientSearchResult({
  result,
  selectedId,
  setSelectedId,
  isPatientMerge,
}: {
  result: PatientIndexSearchResult;
  selectedId: string | null;
  setSelectedId: (value: string | null) => void;
  isPatientMerge?: boolean;
}) {
  const theme = useTheme();

  const { t } = useTranslation();

  function formatPatientDateOfBirth(dateOfBirth: string | null | undefined) {
    return dateOfBirth
      ? dayjs(dateOfBirth).format("DD/MM/YYYY")
      : t("common.unknown");
  }

  return (
    <>
      <Box sx={{ my: theme.spacing(4) }}>
        <PatientSearchResultBanner
          outcome={result.outcome}
          isPatientMerge={isPatientMerge}
        />
      </Box>

      {result.matchedPatients.map((patient) => {
        return (
          <Stack gap="1rem">
            <FormTitle
              useReducedTopPadding
              hasTitleBottomMargin={false}
              hasContainerMarginBottom={false}
              titleText={t("components.cardExtended.title")}
            />
            <PatientCardExtended
              patientName={`${patient.name.given}  ${patient.name.family}`}
              patientDateOfBirth={formatPatientDateOfBirth(patient.dateOfBirth)}
              address={patient.address.address}
              patientAge={
                patient.dateOfBirth
                  ? dayjs().diff(patient.dateOfBirth, "year").toString()
                  : t("common.unknown")
              }
              isSelected={selectedId === patient.id}
              setIsSelected={() =>
                setSelectedId(selectedId === patient.id ? null : patient.id)
              }
            />
          </Stack>
        );
      })}
    </>
  );
}
