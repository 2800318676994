import {
  formIsInProgress,
  formIsRecordOfDetention,
  PatientTimelineResponse,
} from "@aspire/common";

export function hasInProgressWork(
  patientTimeline: PatientTimelineResponse | undefined,
) {
  const patientTimelineAdmissions = patientTimeline?.inProgressWork?.admissions;

  const hasStandaloneOrMultiFormsInProgress =
    patientTimeline?.inProgressWork?.items?.find((i) =>
      i.forms.find((i) => formIsInProgress(i)),
    );

  // This is checking that every admission within the form context has a completed h3 form, if it does we should treat the admission not in progress anymore
  const allAdmissionsComplete = (
    patientTimeline?.inProgressWork?.admissions || []
  ).every((admission) =>
    admission.forms.some(
      (form) => formIsRecordOfDetention(form) && form.status === "complete",
    ),
  );

  const hasFormInProgressForms =
    hasStandaloneOrMultiFormsInProgress ||
    (patientTimelineAdmissions &&
      patientTimelineAdmissions.length > 0 &&
      !allAdmissionsComplete);

  return hasFormInProgressForms;
}
