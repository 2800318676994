import { ExternalPatientLinkEnhanced } from "@aspire/common";
import { CloudDownload as CloudDownloadIcon } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { ukLocalFormatDateTime } from "~/util.js";

export type ManualPullButtonProps = Pick<
  ExternalPatientLinkEnhanced,
  "externalSystemType" | "externalSystemDisplayName" | "dateLastProcessed"
> & {
  onSync: () => Promise<void>;
  allExternalSystemDisplayNames: string[];
};

export const ManualPullButton = ({
  externalSystemType,
  externalSystemDisplayName,
  dateLastProcessed,
  onSync,
  allExternalSystemDisplayNames,
}: ManualPullButtonProps) => {
  const { t } = useTranslation();
  const [synchronizing, setSynchronizing] = React.useState(false);

  const buttonTitle =
    externalSystemType === "rio"
      ? t("components.externalPatientLink.manualPullButton.syncWithRio")
      : externalSystemType === "pds"
        ? t("components.externalPatientLink.manualPullButton.syncWithPds")
        : t("components.externalPatientLink.manualPullButton.sync");

  const currentDate = dayjs();
  const nearThreshold = currentDate.subtract(7, "days");
  const farThreshold = currentDate.subtract(14, "days");

  const badgeColor =
    dateLastProcessed === undefined
      ? "error"
      : dayjs(dateLastProcessed).isAfter(nearThreshold)
        ? undefined
        : dayjs(dateLastProcessed).isAfter(farThreshold)
          ? "warning"
          : "error";

  const startIcon = (
    <Box sx={{ display: "flex", width: "1.5rem" }}>
      {synchronizing ? (
        <CircularProgress size={"1rem"} data-testid="spinner" />
      ) : badgeColor === undefined ? (
        <CloudDownloadIcon />
      ) : (
        <Badge
          data-testid={`badge-${badgeColor}`}
          color={badgeColor}
          variant="dot"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <CloudDownloadIcon />
        </Badge>
      )}
    </Box>
  );

  return (
    <Box data-testid="manual-pull-button">
      <Tooltip
        placement="left"
        enterDelay={500}
        arrow
        title={
          <Stack>
            <Typography>{externalSystemDisplayName}</Typography>
            {dateLastProcessed && (
              <Typography>
                Last processed{" "}
                {ukLocalFormatDateTime(dayjs(dateLastProcessed).toISOString())}
              </Typography>
            )}
            {allExternalSystemDisplayNames.length > 1 && (
              <Typography>
                This record is linked to{" "}
                {allExternalSystemDisplayNames
                  .filter((x) => x.length > 0)
                  .join(", ")}
              </Typography>
            )}
          </Stack>
        }
      >
        <span>
          <Button
            variant="outlined"
            startIcon={startIcon}
            sx={{ minWidth: "max-content" }}
            disabled={synchronizing}
            onClick={async () => {
              setSynchronizing(true);
              try {
                await onSync();
              } finally {
                setSynchronizing(false);
              }
            }}
          >
            {buttonTitle}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
