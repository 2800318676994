import { apiHooks } from "~/api.js";

import { UUID } from "@aspire/common";

import { useSyncExternalPatientLinks } from "./common/syncExternalPatientLink.js";
import { useUploadFormPdfViaExternalPatientLinks } from "./common/uploadFormPdfViaExternalPatientLink.js";

export function useExternalPatientLinks({
  patientId,
  patientIdsToTreatAsRootPatients,
}: {
  patientId: UUID;
  patientIdsToTreatAsRootPatients?: string[];
}) {
  // Get details of the patient links
  const [
    { data: externalPatientLinks, loading: externalPatientLinksLoading },
    reloadExternalPatientLinks,
  ] = apiHooks.patients.getExternalPatientLinks(patientId, {
    patientIdsToTreatAsRootPatients: patientIdsToTreatAsRootPatients ?? [],
  });

  const {
    Component: SyncComponent,
    componentProps: syncProps,
    syncExternalPatientLink,
  } = useSyncExternalPatientLinks(patientId);

  const {
    Component: UploadComponent,
    componentProps: uploadProps,
    uploadFormPdfViaExternalPatientLink,
    getExternalPatientFormPushEvent,
  } = useUploadFormPdfViaExternalPatientLinks();

  // Compose the components we need into a singular
  // thing to allow the hooks to display their own ui
  const Component = () => (
    <>
      <SyncComponent {...syncProps} />
      <UploadComponent {...uploadProps} />
    </>
  );

  return {
    externalPatientLinksLoading,
    externalPatientLinks,
    reloadExternalPatientLinks,
    syncExternalPatientLink,
    uploadFormPdfViaExternalPatientLink,
    getExternalPatientFormPushEvent,
    patientForReview: syncProps.patientRecords,
    Component,
  };
}
