import {
  SuccessPatientIndexSearchResultV2,
  TooManyMatchesPatientIndexSearchResultV2,
} from "@aspire/common";
import { CircularProgress, Stack } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { v4 } from "uuid";
import { api } from "~/api.js";
import {
  Banner,
  BannerList,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/FormFooter.js";
import { PatientBanner } from "~/components/layout/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { usePatientTimeline } from "~/hooks/apiCalls.js";
import { routeFns } from "~/routes.js";
import { CollectCriteria } from "../PatientSearchV2/CollectCriteria.js";
import { CollectCriteriaDemographicsProps } from "../PatientSearchV2/CollectCriteriaDemographics.js";
import { CollectCriteriaNhsNumberProps } from "../PatientSearchV2/CollectCriteriaNhsNumber.js";
import { SearchResults } from "../PatientSearchV2/SearchResults.js";
import { ageRangeToDateRange } from "../PatientSearchV2/util.js";

type PatientMergeSearchProps = {};

type SearchResultsAndHandlers = {
  searchResults:
    | "loading"
    | TooManyMatchesPatientIndexSearchResultV2
    | SuccessPatientIndexSearchResultV2;

  /**
   * The function we will call if we want to continue with a search result.
   * @param index The index of the selected search result.
   * @returns
   */
  onContinue?: (index: number) => void;
};

export function PatientMergeSearch(props: PatientMergeSearchProps) {
  const userContext = React.useContext(LoggedInUserContext);
  const hasPatientMergeAccess =
    userContext?.user?.hasPatientMergeAccess ?? false;
  const hasPhoneticMatchAccess =
    userContext?.user?.sessionOrganisationConfiguration
      ?.phoneticPatientIndexSearchEnabled ?? false;
  const canSearchByNhsNumber =
    userContext?.user.sessionOrganisationConfiguration?.nhsNumberEnabled ??
    false;

  const { fromPatientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Redirect away if merge v2 not enabled
  React.useEffect(() => {
    if (
      userContext &&
      !(
        userContext.user?.sessionOrganisationConfiguration?.mergeV2Enabled &&
        hasPatientMergeAccess
      )
    ) {
      renderErrorToast({
        message: "Patient merge v2 is not enabled for this user",
      });
      navigate(routeFns.home());
    }
  }, [userContext]);

  const {
    patientTimeline: fromPatientTimeline,
    patientTimelineCompleteLoading: fromPatientTimelineLoading,
    reloadPatientTimeline,
  } = usePatientTimeline({
    patientId: fromPatientId,
  });

  const [criteriaType, setCriteriaType] = React.useState<
    "nhs-number" | "demographics" | "pds-demographics"
  >("nhs-number");

  const [searchResultsAndHandlers, setSearchResultsAndHandlers] =
    React.useState<SearchResultsAndHandlers | undefined>(undefined);

  // This is where we store the index of the selected search
  const [searchResultIndexSelected, setSearchResultIndexSelected] =
    React.useState<number | null>(null);

  const patientSearchResultRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    patientSearchResultRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [searchResultsAndHandlers]);

  const state =
    searchResultsAndHandlers === undefined
      ? "search"
      : searchResultsAndHandlers.searchResults === "loading"
        ? "searching"
        : searchResultsAndHandlers.searchResults.searchOutcome ===
            "too-many-matches"
          ? "too-many-matches"
          : searchResultsAndHandlers.searchResults.pdsResult ===
              "too-many-matches"
            ? "too-many-matches-pds"
            : searchResultsAndHandlers.searchResults.matchedPatients.length ===
                0
              ? "results-not-found"
              : searchResultIndexSelected === null
                ? "results-found"
                : "result-selected";
  const tooManyMatches = state === "too-many-matches";

  const onContinueFn =
    (data: SuccessPatientIndexSearchResultV2) => async (index: number) => {
      const patient = data.matchedPatients[index];

      const searchReasoningResult = await api.patients.addSearchV2Reasoning(
        patient.resultId,
        {
          reason: "Patient Merge",
        },
      );

      if (searchReasoningResult.status !== 200) {
        renderErrorToast({
          message: "Failed to add search reasoning - please try again later",
        });
        return;
      }

      if (
        searchReasoningResult.data.type === "patient-page" ||
        searchReasoningResult.data.type === "rio-link-check"
      ) {
        const toPatientId =
          searchReasoningResult.data.type === "patient-page"
            ? searchReasoningResult.data.patientId
            : searchReasoningResult.data.patientPageDetails?.patientId;

        if (toPatientId) {
          navigate(
            routeFns.patientMergeConfirmation(fromPatientId!, toPatientId),
          );
        }
      }
    };

  // Search for patients using NHS Number
  const searchByNhsNumber: CollectCriteriaNhsNumberProps["onSubmit"] = async (
    values,
  ) => {
    setSearchResultsAndHandlers({ searchResults: "loading" });

    // Look for an existing aspire patient with this NHS Number
    const { data } = await api.patients.searchV2(v4(), {
      type: "nhs-number",
      nhsNumber: values.nhsNumber,
      isMergePatientSearch: true,
      patientIdToExclude: fromPatientId!,
    });

    if (data.searchOutcome === "too-many-matches") {
      setSearchResultsAndHandlers({ searchResults: data });
      return;
    }
    setSearchResultsAndHandlers({
      searchResults: data,
      onContinue: onContinueFn(data),
    });
  };

  // Search for patients using Demographics
  const searchByDemographics: CollectCriteriaDemographicsProps["onSubmit"] =
    async (values) => {
      setSearchResultsAndHandlers({ searchResults: "loading" });

      const { data } = await api.patients.searchV2(v4(), {
        type: "demographics",
        enablePhoneticMatchOnSurname: values.usePhoneticSurnameMatch,
        enablePhoneticMatchOnGivenName: values.usePhoneticGivenNameMatch,

        given: values.givenName ?? undefined,
        surname: values.familyName!,
        birthdate: values.dateOfBirthIsExact
          ? values.dateOfBirthExact
          : ageRangeToDateRange(values.ageApprox),

        isMergePatientSearch: true,
        patientIdToExclude: fromPatientId!,
      });

      if (data.searchOutcome === "too-many-matches") {
        setSearchResultsAndHandlers({ searchResults: data });
        return;
      }

      setSearchResultsAndHandlers({
        searchResults: data,
        onContinue: onContinueFn(data),
      });
    };

  return (
    <PopupDialog
      open={true}
      fullWidth
      onClose={() => {
        navigate(routeFns.patientHome(fromPatientId!));
      }}
    >
      <PopupDialogTitle
        titleText={`Merge Patient`}
        closeDialog={() => {
          navigate(routeFns.patientHome(fromPatientId!));
        }}
      />
      {fromPatientTimelineLoading || !fromPatientTimeline ? (
        <CircularProgress />
      ) : (
        <Stack>
          <PatientBanner
            patientTimeline={fromPatientTimeline}
            patient={fromPatientTimeline.patient}
            reloadPatientTimeline={reloadPatientTimeline}
            nhsNumber={fromPatientTimeline.patient.nhsNumber ?? undefined}
            disableSticky={false}
          />
          <Banner
            bannerType={BannerList.WARNING}
            title={"Please search for a patient to merge this patient into"}
          />
          <Stack sx={{ mt: "2rem" }}>
            <CollectCriteria
              canSearchByPdsDemographics={false}
              canUsePhoneticNameMatch={hasPhoneticMatchAccess}
              canSearchByNhsNumber={canSearchByNhsNumber}
              onSubmitNhsNumber={searchByNhsNumber}
              onSubmitDemographics={searchByDemographics}
              onSubmitPdsDemographics={() => {}}
              criteriaType={criteriaType}
              setCriteriaType={setCriteriaType}
              disabled={["searching", "result-selected"].includes(state)}
            >
              {({ onClear }) => {
                return (
                  <>
                    <SearchResults
                      patientSearchResultRef={patientSearchResultRef}
                      searchResult={
                        tooManyMatches
                          ? "too-many-matches"
                          : (searchResultsAndHandlers?.searchResults as SuccessPatientIndexSearchResultV2)
                      }
                      onSelectedIndexChanged={setSearchResultIndexSelected}
                    />
                    <FormFooterSection
                      disableSubmit={["searching"].includes(state)}
                      saveLabel={
                        [
                          "search",
                          "searching",
                          "results-not-found",
                          "too-many-matches",
                          "too-many-matches-pds",
                          "results-found",
                        ].includes(state)
                          ? t("buttonLabels.search")
                          : t("buttonLabels.continue")
                      }
                      discardLabel="Clear Search"
                      performSaveActionOnFormSubmit={[
                        "search",
                        "results-not-found",
                        "results-found",
                        "too-many-matches",
                        "too-many-matches-pds",
                      ].includes(state)}
                      onSave={
                        state === "result-selected"
                          ? () => {
                              if (searchResultIndexSelected !== null)
                                searchResultsAndHandlers?.onContinue?.(
                                  searchResultIndexSelected,
                                );
                            }
                          : () => {}
                      }
                      onCancel={() => {
                        setSearchResultsAndHandlers(undefined);
                        setSearchResultIndexSelected(null);
                        onClear();
                      }}
                    />
                  </>
                );
              }}
            </CollectCriteria>
          </Stack>
        </Stack>
      )}
    </PopupDialog>
  );
}
