import {
  SuccessPatientIndexSearchResultV2,
  TooManyMatchesPatientIndexSearchResultV2,
} from "@aspire/common";
import { Stack } from "@mui/material";
import { useDialogs } from "@toolpad/core/useDialogs";
import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { api } from "~/api.js";
import { renderErrorToast } from "~/components/design-system/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { CollectCriteria } from "./CollectCriteria.js";
import {
  CollectCriteriaDemographicsProps,
  ValidationSchema as ValidationSchemaDemographics,
} from "./CollectCriteriaDemographics.js";
import { CollectCriteriaNhsNumberProps } from "./CollectCriteriaNhsNumber.js";
import { CollectCriteriaPdsDemographicsProps } from "./CollectCriteriaPdsDemographics.js";
import { CreatePatient } from "./CreatePatient.js";
import { Footer } from "./Footer.js";
import { Header } from "./Header.js";
import { SearchReasoningDialog } from "./SearchReasoningDialog.js";
import { SearchResults } from "./SearchResults.js";
import { ageRangeToDateRange } from "./util.js";

export type PatientSearchProps = Record<string, never>;

/**
 * Contains a set of search results, and the handlers to act upon them.
 * This combination allows us to have different journeys depending on
 * whether the search was done by demographics or NHS Number.
 */
type SearchResultsAndHandlers = {
  searchResults:
    | "loading"
    | TooManyMatchesPatientIndexSearchResultV2
    | SuccessPatientIndexSearchResultV2;

  /**
   * The function we will call if we want to continue with a search result.
   * @param index The index of the selected search result.
   * @returns
   */
  onContinue?: (index: number) => void;

  /**
   * Called when the user wants to create a patient.
   * This should return the demographics to pre-fill the create patient form with.
   * @returns The demographics to pre-fill the create patient form with.
   */
  onCreate?: () => ValidationSchemaDemographics | undefined;
};

export const PatientSearch = ({}: PatientSearchProps) => {
  const dialogs = useDialogs();
  const navigate = useNavigate();

  const canSearchByNhsNumber = useSearchByNhsNumberEnabled();
  const canSearchInPds = usePdsEnabled();
  const canUsePhoneticNameMatch = usePhoneticSearchEnabled();

  const rioInstance = useRioInstance();

  // Whether we are creating a patient having failed to find one.
  const [isCreatingPatient, setIsCreatingPatient] = useState(false);

  // This is where we store the search result from the API
  const [searchResultsAndHandlers, setSearchResultsAndHandlers] = useState<
    SearchResultsAndHandlers | undefined
  >(undefined);

  // This is where we store the index of the selected search
  const [searchResultIndexSelected, setSearchResultIndexSelected] = useState<
    number | null
  >(null);

  // Which type of criteria the user is currently using to search
  const [criteriaType, setCriteriaType] = React.useState<
    "nhs-number" | "demographics" | "pds-demographics"
  >(canSearchByNhsNumber ? "nhs-number" : "demographics");

  // Reset the search results when the criteria type changes
  React.useEffect(() => {
    setSearchResultsAndHandlers(undefined);
    setSearchResultIndexSelected(null);
  }, [criteriaType]);

  // Calculate the overall state of the page
  const state =
    searchResultsAndHandlers === undefined
      ? "search"
      : searchResultsAndHandlers.searchResults === "loading"
        ? "searching"
        : searchResultsAndHandlers.searchResults.searchOutcome ===
            "too-many-matches"
          ? "too-many-matches"
          : searchResultsAndHandlers.searchResults.pdsResult ===
              "too-many-matches"
            ? "too-many-matches-pds"
            : searchResultsAndHandlers.searchResults.matchedPatients.length ===
                0
              ? "results-not-found"
              : searchResultIndexSelected === null
                ? "results-found"
                : "result-selected";

  const onContinueFn =
    (data: SuccessPatientIndexSearchResultV2) => async (index: number) => {
      const selectedSearchResultId = data.matchedPatients[index].resultId;

      const response = await dialogs.open(SearchReasoningDialog);
      if (response.type === "cancelled") {
        return;
      }

      // Record the search reason
      const result = await api.patients.addSearchV2Reasoning(
        selectedSearchResultId,
        {
          reason: response.reasoning.label,
          reasonDescription:
            response.reasoning.reason === "other"
              ? response.reasoning.description
              : undefined,
        },
      );

      if (result.status === 200) {
        if (result.data.type === "patient-page") {
          if (result.data.externalLinkToSync) {
            navigate(
              routeFns.patientSyncExternalLink(
                result.data.patientId,
                result.data.externalLinkToSync,
              ),
            );
          } else {
            navigate(routeFns.patientHome(result.data.patientId));
          }
        } else if (result.data.type === "rio-link-check") {
          navigate(
            routeFns.patientCreateFromExternalId(
              result.data.clickthroughToken,
              result.data.patientPageDetails
                ? result.data.patientPageDetails.externalLinkToSync
                  ? routeFns.patientSyncExternalLink(
                      result.data.patientPageDetails.patientId,
                      result.data.patientPageDetails.externalLinkToSync,
                    )
                  : routeFns.patientHome(
                      result.data.patientPageDetails.patientId,
                    )
                : undefined,
            ),
          );
        } else if (result.data.type === "s-flag-setup") {
          navigate(routeFns.patientEdit(result.data.patientId));
        } else {
          alert("Unsupported response type - " + result.data.type);
        }
      } else {
        renderErrorToast({
          message:
            "Failed to record search reasoning - please try your search again",
        });
      }
    };

  // Search for patients using NHS Number
  const searchByNhsNumber: CollectCriteriaNhsNumberProps["onSubmit"] = async (
    values,
  ) => {
    setSearchResultsAndHandlers({ searchResults: "loading" });

    // Look for an existing aspire patient with this NHS Number
    const { data } = await api.patients.searchV2(v4(), {
      type: "nhs-number",
      nhsNumber: values.nhsNumber,
      isMergePatientSearch: false,
    });

    if (data.searchOutcome === "too-many-matches") {
      setSearchResultsAndHandlers({ searchResults: data });
      return;
    }

    // TODO: preserve the Rio journey here in case PDS not enabled
    // If PDS _was_ enabled and there are no results, looking in Rio is pointless
    // and we should just go to patient creation.

    // Look in Rio
    // TODO: This just redirects us onto a clickthrough journey.
    //       This is not how we will want this to work when we do PDS as well.
    //       We should do a proper search in Rio here, present the results in-situ,
    //       and if they fail continue with a PDS search.
    if (data.matchedPatients.length === 0 && rioInstance && !canSearchInPds) {
      // Create a claimed click-through token
      const token = v4();
      const tokenResponse = await api.rio.clickThrough.createAndClaimToken({
        token,
        externalSystemType: "rio",
        externalSystemId: rioInstance.instanceId,
        externalPatientIdType: "nhs.number",
        externalPatientIdValue: values.nhsNumber,
      });

      if (tokenResponse.status === 204) {
        // Redirect to the click-through flow to redeem the token and continue the journey
        navigate(routeFns.patientCreateFromExternalId(token));
      }
    }

    setSearchResultsAndHandlers({
      searchResults: data,
      onContinue: onContinueFn(data),
      onCreate: () => {
        // We don't have any information about demographics to
        // pre-fill the create patient form with.
        return undefined;
      },
    });
  };

  // Search for patients using Demographics
  const searchByDemographics: CollectCriteriaDemographicsProps["onSubmit"] =
    async (values) => {
      setSearchResultsAndHandlers({ searchResults: "loading" });

      const { data } = await api.patients.searchV2(v4(), {
        type: "demographics",
        enablePhoneticMatchOnSurname: values.usePhoneticSurnameMatch,
        enablePhoneticMatchOnGivenName: values.usePhoneticGivenNameMatch,

        given: values.givenName ?? undefined,
        surname: values.familyName!,
        birthdate: values.dateOfBirthIsExact
          ? values.dateOfBirthExact
          : ageRangeToDateRange(values.ageApprox),
        isMergePatientSearch: false,
      });

      if (data.searchOutcome === "too-many-matches") {
        setSearchResultsAndHandlers({ searchResults: data });
        return;
      }

      setSearchResultsAndHandlers({
        searchResults: data,
        onContinue: onContinueFn(data),
        onCreate: () => {
          // We will use these demographics to pre-fill the create
          // patient form with, if they are not happy with the search results.
          return values;
        },
      });
    };

  // Search for patients using PDS Demographics
  const searchByPdsDemographics: CollectCriteriaPdsDemographicsProps["onSubmit"] =
    async (values) => {
      setSearchResultsAndHandlers({ searchResults: "loading" });

      const { data } = await api.patients.searchV2(v4(), {
        type: "pds-demographics",

        postcode: values.postcode ?? undefined,
        given: values.givenName ?? undefined,
        surname: values.familyName!,
        birthdate: values.dateOfBirthIsExact
          ? values.dateOfBirthExact
          : ageRangeToDateRange(values.ageApprox),
        isMergePatientSearch: false,
      });

      if (data.searchOutcome === "too-many-matches") {
        setSearchResultsAndHandlers({ searchResults: data });
        return;
      }

      setSearchResultsAndHandlers({
        searchResults: data,
        onContinue: onContinueFn(data),
        onCreate: () => {
          // We will use these demographics to pre-fill the create
          // patient form with, if they are not happy with the search results.
          return {
            familyName: values.familyName,
            givenName: values.givenName,
            dateOfBirthExact: values.dateOfBirthExact,
            dateOfBirthIsExact: values.dateOfBirthIsExact,

            usePhoneticGivenNameMatch: false,
            usePhoneticSurnameMatch: false,

            ageApprox: values.ageApprox,
          };
        },
      });
    };

  // Show the create patient page
  const onCreate = async () => {
    setIsCreatingPatient(true);
  };

  const tooManyMatches = state === "too-many-matches";
  const tooManyMatchesPds = state === "too-many-matches-pds";

  return isCreatingPatient ? (
    <CreatePatient
      initialValues={searchResultsAndHandlers?.onCreate?.()}
      onCancel={() => {
        setSearchResultsAndHandlers(undefined);
        setSearchResultIndexSelected(null);
        setIsCreatingPatient(false);
      }}
    />
  ) : (
    <Stack gap="1rem">
      <Header />

      <CollectCriteria
        canSearchByPdsDemographics={canSearchInPds}
        canUsePhoneticNameMatch={canUsePhoneticNameMatch}
        canSearchByNhsNumber={canSearchByNhsNumber}
        onSubmitNhsNumber={searchByNhsNumber}
        onSubmitDemographics={searchByDemographics}
        onSubmitPdsDemographics={searchByPdsDemographics}
        criteriaType={criteriaType}
        setCriteriaType={setCriteriaType}
        disabled={["searching", "result-selected"].includes(state)}
      >
        {({ onClear }) => {
          return (
            <>
              <SearchResults
                searchResult={
                  tooManyMatches
                    ? "too-many-matches"
                    : tooManyMatchesPds
                      ? "too-many-matches-pds"
                      : (searchResultsAndHandlers?.searchResults as SuccessPatientIndexSearchResultV2)
                }
                onSelectedIndexChanged={setSearchResultIndexSelected}
              />

              <Footer
                state={state}
                onContinue={() => {
                  if (searchResultIndexSelected !== null)
                    searchResultsAndHandlers?.onContinue?.(
                      searchResultIndexSelected,
                    );
                }}
                onClear={() => {
                  // Clear the Formik fields
                  onClear();

                  // Clear the previous search results
                  setSearchResultsAndHandlers(undefined);
                  setSearchResultIndexSelected(null);
                }}
                onCreate={onCreate}
              />
            </>
          );
        }}
      </CollectCriteria>
    </Stack>
  );
};

const useSearchByNhsNumberEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration?.nhsNumberEnabled ??
    false
  );
};

const usePdsEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration?.pdsEnabled ?? false
  );
};

const usePhoneticSearchEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration
      ?.phoneticPatientIndexSearchEnabled ?? false
  );
};

const useRioInstance = () => {
  const userContext = useContext(LoggedInUserContext);

  return userContext?.user?.sessionOrganisationConfiguration
    ?.rioInstancesEnabled?.[0];
};
