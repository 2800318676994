import {
  FormContextData,
  formIsCompleted,
  formIsInProgress,
  PatientTimelineResponse,
} from "@aspire/common";
import { Box, Card, CircularProgress, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { apiHooks } from "~/api.js";
import {
  Button,
  PopupDialog,
  renderErrorToast,
} from "~/components/design-system/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { FormContextPdfViewer } from "../helpers/FormContextPdfViewer.js";
import { ParticipantsList } from "../helpers/ParticipantsList.js";

function SynchroniseIncidentDialog({
  open,
  onClose,
  formContext,
  reloadFormContext,
}: {
  open: boolean;
  onClose: () => void;
  formContext: FormContextData;
  reloadFormContext: () => void;
}) {
  const [{ loading, data, response }] =
    apiHooks.vision.synchroniseIncidentForFormContext(formContext.id);

  React.useEffect(() => {
    if (!loading) {
      if (response?.status === 204) {
        onClose();
      } else if (response?.status === 200) {
        reloadFormContext();
        onClose();
      } else {
        renderErrorToast({ message: "Failed to synchronise incident" });
      }
    }
  }, [loading, data, response]);

  return (
    <PopupDialog open={open} onClose={onClose} title="Synchronise Incident">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 4,
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        Synchronising with police system...
        <CircularProgress />
      </Box>
    </PopupDialog>
  );
}

export function PoliceIncidentFormPage({
  reloadFormContext,
  formContext,
  patientTimeline,
  reloadPatientTimeline,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  patientTimeline: PatientTimelineResponse | null;
  reloadPatientTimeline: () => void;
}) {
  const policeForm = formContext.forms.find(
    (f) => f.formTemplate.id === "mha-136",
  )!;
  const [syncDialogOpen, setSyncDialogOpen] = useState(true);
  const userContext = React.useContext(LoggedInUserContext);
  const { user } = userContext!;
  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  const nhsNumber = patientTimeline?.patient.nhsNumber;

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Box
      data-testid="form-context:police-incident"
      data-formcontextid={formContext.id}
    >
      {syncDialogOpen && (
        <SynchroniseIncidentDialog
          open={syncDialogOpen}
          onClose={() => {
            setSyncDialogOpen(false);
            setPdfViewFormId(policeForm.id);
          }}
          formContext={formContext}
          reloadFormContext={reloadFormContext}
        />
      )}
      {pdfViewFormId && (
        <FormContextPdfViewer
          inModal={true}
          forms={formContext.forms
            .filter((f) => formIsCompleted(f) || formIsInProgress(f))
            .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1))}
          setFormId={setPdfViewFormId}
          formId={pdfViewFormId}
        />
      )}

      <Box sx={{ mb: 3 }}>
        {patientTimeline && (
          <PatientBanner
            patient={formContext.patient}
            nhsNumber={nhsNumber ?? undefined}
            disableSticky
            isHorizontalLineHidden
            patientTimeline={patientTimeline}
            reloadPatientTimeline={reloadPatientTimeline}
          />
        )}
      </Box>

      <Stack sx={{ display: "flex" }}>
        <Card
          sx={{ width: "100%", padding: "2rem", mb: "2rem" }}
          onClick={() => setPdfViewFormId(policeForm.id)}
        >
          <Typography variant="h5" gutterBottom>
            1. Police Incident Form
          </Typography>
        </Card>

        <Card sx={{ width: "100%", padding: "2rem", mb: "2rem" }}>
          <Typography variant="h5" gutterBottom>
            2. Request Police Presence
          </Typography>
          <Typography variant="caption" gutterBottom>
            Do you need ongoing police presence?
          </Typography>
          <Box sx={{ display: "flex", gap: "2rem", mt: "0.5rem" }}>
            <Button label="No" variant="outlined" color="inherit" />
            <Button label="Yes" variant="outlined" color="inherit" />
          </Box>
        </Card>

        <Card
          sx={{ width: "100%", padding: "2rem", mb: "2rem" }}
          onClick={() => alert("Not implemented")}
        >
          <Typography variant="h5" gutterBottom>
            3. Section 136 Outcome Form
          </Typography>
        </Card>
      </Stack>

      <ParticipantsList formContext={formContext} />
    </Box>
  );
}
