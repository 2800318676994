export default {
  layout: {
    patientBanner: {
      name: "Name",
      unknown: "Unknown",
      dateOfBirth: "Born",
      nhsNumber: "NHS no",
      age: "Age",
      address: "Address",
      postcode: "Postcode",
      link: "Go to patient",
      edit: "Edit patient",
      merge: "Merge patient",
      undoMerge: "Undo Merge",
    },
    menu: {
      searchAndDropdownMenuLabel: "Team",
      openMenuLabel: "open drawer",
      profileLabel: "profile",
      changeContext: "(change)",
    },
    termsAndConditionsModal: {
      confirmationText: "I confirm acceptance of the above",
      acceptanceError:
        "Failed to accept terms and conditions - please contact support",
      acceptanceSuccess: "Terms and conditions successfully accepted",
    },
  },
};
