import React from "react";
import { Banner, BannerList } from "~/components/design-system/index.js";

import { PatientSearchOutcome } from "@aspire/common";
import { useTranslation } from "react-i18next";

export type PatientSearchResultBannerProps = {
  outcome: PatientSearchOutcome;
  isPatientMerge?: boolean;
};

export const PatientSearchResultBanner = ({
  outcome,
  isPatientMerge,
}: PatientSearchResultBannerProps) => {
  const { t } = useTranslation();
  switch (outcome) {
    case "success/unique-match":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.uniqueMatch.title",
          )}
          bannerType={BannerList.CONFIRMATION}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.uniqueMatch.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.uniqueMatch.patientMergeBody2",
            )
          }
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.uniqueMatch.title",
          )}
          bannerType={BannerList.CONFIRMATION}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.uniqueMatch.body1",
            ) +
            "\n" +
            t("pages.patientSearch.patientSearchResultBanner.uniqueMatch.body2")
          }
        />
      );

    case "error/too-many-matches-pds":
      return (
        <Banner
          title={"Too many matches were found"}
          bannerType={BannerList.ERROR}
          body={
            "A unique match was not found on PDS based on your search criteria. Please narrow down your search criteria and try again."
          }
        />
      );

    case "error/too-many-matches":
      return (
        <Banner
          title={"Too many matches were found"}
          bannerType={BannerList.ERROR}
          body={
            "More than 10 results matched the criteria you specified in the eMHA Patient Index. Please narrow down your search criteria (or disable phonetic matching if it was enabled) and try again."
          }
        />
      );

    case "info/multiple-matches":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.multipleMatches.title",
          )}
          bannerType={BannerList.WARNING}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.patientMergeBody2",
            )
          }
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.multipleMatches.title",
          )}
          bannerType={BannerList.WARNING}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.body2",
            )
          }
        />
      );

    case "error/no-matches":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.title",
          )}
          bannerType={BannerList.ERROR}
          body={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.patientMergeBody",
          )}
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.title",
          )}
          bannerType={BannerList.ERROR}
          body={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.body",
          )}
        />
      );

    case "error/unknown":
      return (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.unknown.title",
          )}
          bannerType={BannerList.ERROR}
          body={t("pages.patientSearch.patientSearchResultBanner.unknown.body")}
        />
      );
  }
};
