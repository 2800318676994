export const mergeReasonValues = [
  {
    label: "Patient record created in error",
    value: "patientCreatedInError",
  },
  {
    label: "Patient name unknown at time of form completion",
    value: "patientNameUnknown",
  },
  {
    label: "No access to primary patient at time of form completion",
    value: "noAccessToPrimaryPatient",
  },
  { label: "Patient Alias", value: "patientAlias" },
  {
    label: "Other",
    value: "other",
  },
];
