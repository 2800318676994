export default {
  common: {
    name: "Name",
    address: "Address",
    postalCode: "Postcode",
    email: "Email",
    role: "Role",
    breadcrumb: "breadcrumb",
    managingOrganisation: "Managing Organisation",
    dataController: "Data Controller",
    childOrganisations: "Child Organisations",
    teams: "Teams",
    members: "Members",
    currentTeam: "Current Team:",
    unknown: "Unknown",
    yes: "Yes",
    no: "No",
    status: "Status",
    form: "Form",
    forms: "Forms",
    newForm: "New Form",
    newAdmissionForm: "New Admission Form",
    and: "and",
    sendForm: "Send Form",
    sendRequest: "Send request",
    sendInvite: "Send invite",
    clickHere: "Click here",
    merge: "Merged",
    refresh: "Refresh",
  },
  buttonLabels: {
    patientLog: "PATIENT LOG",
    activityLog: "ACTIVITY LOG",
    claim: "CLAIM",
    complete: "Complete",
    create: "CREATE",
    close: "CLOSE",
    save: "SAVE",
    goBack: "GO BACK",
    sign: "Sign",
    confirm: "CONFIRM",
    filter: "FILTER",
    exit: "EXIT",
    shareForm: "SHARE FORM",
    continue: "CONTINUE",
    start: "START",
    logout: "LOGOUT",
    viewForm: "VIEW FORM",
    continueDraft: "CONTINUE DRAFT",
    startDraft: "START DRAFT",
    view: "VIEW",
    goCompletion: "GO TO COMPLETION",
    assignmentRejection: "REJECT THE ASSIGNMENT",
    clearSearch: "CLEAR SEARCH",
    search: "SEARCH",
    createPatient: "CREATE PATIENT",
    updatePatient: "UPDATE PATIENT",
    backSearch: "BACK TO SEARCH",
    backPatient: "BACK TO PATIENT",
    home: "HOME PAGE",
    clear: "CLEAR",
    finalise: "FINALISE",
    launch: "Launch",
    createAssignment: "Create Assignment",
    invite: "Invite",
    cancelAssignment: "Cancel Assignment",
    send: "Send",
    archive: "Archive",
    preview: "PREVIEW PDF",
    searchPatient: "SEARCH PATIENT",
    startNew: "START NEW",
    action: "ACTION",
    merge: "MERGE",
    advancedSearch: "ADVANCED SEARCH",
    backToForm: "Back to Form",
    uploadForm: "Upload Form",
    submit: "Submit",
  },
  errors: {
    somethingWentWrong: "Something went wrong.",
    somethingWentWrongDescription:
      "Please try refreshing the page or contact support if the issue persists.",
    unknownError: "Unknown error",
    userLoadingErrorMessage:
      " Apologies, we're experiencing difficulty in loading your user data at the moment. Please try again later.",
    dashboardLoadingErrorMessage:
      " Apologies, we're experiencing difficulty in loading your dashboard data at the moment. Please try again later.",
  },
  training: {
    onlyShareToTrainingUsers:
      "You can only share to training users/teams while in training mode",
    cannotShareToTrainingUsers: "You cannot share to an training user/team",
    teamPicker: "TRAINING MODE: ",
    dialog:
      "Welcome! You are signed into a training environment of Thalamos' Digital Mental Health Act Solution. Some functionality is restricted. Please do not use this environment for real patient data! Once you have been trained you will be moved into your live environment and added to the appropriate teams.",
  },
};
