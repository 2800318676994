export default {
  components: {
    cardExtended: {
      title: "Search Results",
      patientName: "Name",
      patientDateOfBirth: "Born",
      address: "Address",
      age: "Age",
      organisation: "Organisation",
      selectPatientLabel: "Select patient",
      patientHistory: "Patient history",
      crisisTeamName: "Crisis team",
      expandMoreLabel: "show more",
    },
    signatureDialog: {
      tabsLabel: "Tabs",
      tabLabelDraw: "Draw",
      tabLabelSavedSignature: "Saved Signature",
      tabLabelUpload: "Upload",
      uploadLabel: "Upload signature",
      defaultCheckboxLabel: "SAVE AS DEFAULT SIGNATURE",
      savedSignatureCheckboxLabel: "USE SAVED SIGNATURE",
      confirmSignature:
        "By clicking confirm, I agree that the signature will be the electronic representation of my signature.",
      uploadedFileHelperText:
        "You are only able to upload jpg and png file types",
    },
    patientMergeDialog: {
      mergeConfirmationMessageOne:
        "You are about to merge this patient into another patient record. All forms for this patient will be merged into the other patient record. This patient record will be removed. The merge can be undone for up to 30 days after the merge was confirmed.",
      mergeConfirmationMessageTwo:
        "If you would like to keep this record as the primary record, please start the merge process from the other patient record.",
      assessmentInProgressMessage:
        "An in-progress admission or form exists for this patient. Please note that patient information will not be updated on any signed forms or form parts.",
      reasonWhyMerge:
        "Please select a reason why you want to merge this patient",
      reasonDescriptionText: "Please describe reason",
      confirmYourAction: "Please Confirm Your Action",
      mergeThesePatients:
        "Are you sure you want to merge these patients? The patient on the left will be merged into the one on the right once you confirm.",
      patientRemoved: "Patient will be removed",
      remainPrimaryPatient: "Patient will remain as primary patient",
      confirmMerge: "Confirm Merge",
    },
    patientUnMergeDialog: {
      subTitleText: "Undo patient merge",
      formCreatedWarning:
        "A form has been created since the merge was confirmed. Please select the form(s) you want to be restored to the patient on the left. Please note that patient information will not be updated on any signed forms / form parts.",
      unmergeBelowPatientsWarning:
        "You are about to undo the merge of the patients displayed below. The patient on the left will be restored from a merge with the patient on the right. The record on the left will be visible again. Are you sure you want to un-merge these patient? ",
      whyUndoMerge:
        "Please select a reason for why you want to undo the merge?",
      patientWillBeRestored: "Patient will be restored",
    },
    menuFlyout: {
      iconButtonLabel: "more",
    },
    workItemCard: {
      claimedStatus: "Claimed",
      claimedByStatus: "Claimed By",
      notYetClaimedStatus: "Not Yet Claimed",
      sentBy: "Sent by",
      unassigned: "Unassigned",
      signedBy: "Signed By",
      notYetSigned: "Not Yet Signed",
      cannotYetSign: "Cannot Yet Sign",
      form: "Form",
      part: "Part",
    },
    dateField: {
      label: "Enter date",
    },
    patientCrisisInfo: {
      text: "for the local Crisis team details to explore potential community provisions and discuss any existing care plans.",
    },
    typeahead: {
      label: "Select form",
      placeholder: "Start typing to search..",
    },
    externalPatientLink: {
      createLinkButton: {
        linkWithRio: "Link with Rio",
        dialog: {
          title: "Patient not linked to Rio",
          body: "This patient is not yet linked to a profile on Rio.\nPlease do the following to allow document upload:\n- Create a Rio profile with matching NHS number.\n- Search again for this patient on eMHA.",
          close: "Close",
        },
      },
      manualPullButton: {
        sync: "Sync",
        syncWithPds: "Sync with PDS",
        syncWithRio: "Sync with Rio",
      },
      reviewExternalPatientRecord: {
        forename: "Forename",
        surname: "Surname",
        dob: "Date of Birth",
        address: "Address",
        unknown: "Unknown",
      },
      reviewNewExternalPatientRecord: {
        title: {
          contacting: "Contacting External System",
          externalPatientLink: "Update Patient Details",
          newPatient: "New Patient Details",
          mergedExternalPatientLink: "Patient has been merged",
        },
        banner: {
          notFound:
            "No patients have been found with the provided details.\nPerform a new search with different data or create a new patient",
          mergedExternalPatientLink:
            "You have clicked through to a patient that has been merged into another patient record.\nClick CLOSE to view the record the patient was merged into.",
          newPatient:
            "Please review patient details.\nIf they are correct, click ACCEPT to create a new patient in eMHA.\nClick SKIP to abandon the process.",
        },
        confirm: "Accept",
        cancel: "Skip",
        toast: {
          failedToCreatePatient: "Failed to create patient",
          failedToCreateLink: "Failed to create a link to the patient",
        },
      },
      reviewExistingExternalPatientRecord: {
        title: "Review Patient Information",
        banner: {
          generic:
            "Patient details in eMHA does not match the information in the external system.\nSelect the correct details below to update patient information in eMHA.\nPatient information will not be updated on any signed forms or form parts.\nClick close to ignore updates.",
          rio: "Patient details in eMHA does not match the information in Rio.\nSelect the correct details below to update patient information in eMHA.\nPatient information will not be updated on any signed forms or form parts.\nClick close to ignore updates.",
          pds: "Patient details in eMHA does not match the information on the Spine (PDS).\nSelect the correct details below to update patient information in eMHA.\nPatient information will not be updated on any signed forms or form parts.\nClick close to ignore updates.",
        },
        confirm: "Update",
        cancel: "Close",
      },
    },
    newFormAssignee: {
      guestUserClarification:
        "This user will be invited as a guest. Please ensure you enter the email address and name carefully and communicate with the recipient outside of Thalamos to confirm receipt. Since they are a guest user, their access will be limited and it is essential that they confirm receipt and complete the action that is required in time.",
    },
  },
};
