import { BasePatientDemographics } from "@aspire/common";

export type SystemType = "eMHA" | "Other";

export type Choice = {
  system: SystemType;
  type: string;
  value: string | null;
};

export type PatientRecords = Record<
  SystemType,
  BasePatientDemographics | undefined
>;

export const doAllValuesMatch = (choices: Choice[]) => {
  const definedChoices = choices.filter(
    (choice) =>
      (choice.value ?? undefined !== undefined) || choice.type === "dob",
  );

  const allChoicesMatch = definedChoices.every(
    (choice) => choice.value === definedChoices[0].value,
  );

  return allChoicesMatch;
};

/**
 * Find the obvious choice.
 * Perhaps there is only one.
 * Or perhaps they are all the same.
 */
export const obviousChoice = (choices: Choice[]) => {
  return doAllValuesMatch(choices)
    ? choices.filter((choice) => choice.value !== undefined)?.[0].system
    : undefined;
};
