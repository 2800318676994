import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routeFns } from "../../routes.js";
import { PatientCreateEdit } from "./PatientCreateEdit.js";

import { ExtendedPatient } from "@aspire/common";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Button,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";
import { WithPatient } from "../../hoc/WithPatient.js";
import { BannerDialog } from "../FormProgressPage/helpers/BannerDialog.js";

export type editPatientDialog = "editPatientDialog";

type PatientEditProps = {
  mode?: editPatientDialog;
  refetchDraft?: () => void;
};

function PatientEditInner({
  mode,
  refetchDraft,
  patient,
  setShowUpdateSuccessDialog,
}: PatientEditProps & {
  patient: ExtendedPatient;
  setShowUpdateSuccessDialog: (value: boolean) => void;
}) {
  const { patientId, formDraftId } = useParams();
  const { t } = useTranslation();
  const isRestrictedPatientSetup =
    patient.restricted && patient.address.address.length === 0;

  const [showRestrictedSetupDialog, setShowRestrictedSetupDialog] =
    React.useState(isRestrictedPatientSetup);

  const navigate = useNavigate();
  const refetchAndRedirect = () => {
    navigate(routeFns.patientEditDialogSuccess(formDraftId!, patientId!));
    refetchDraft && refetchDraft();
  };

  return (
    <Box>
      <PopupDialog
        open={showRestrictedSetupDialog}
        onClose={() => setShowRestrictedSetupDialog(false)}
      >
        <PopupDialogTitle
          titleText={`Patient is restricted`}
          closeDialog={() => setShowRestrictedSetupDialog(false)}
        />
        <Box>
          This patient is marked as restricted in NHS national systems - please
          manually enter an address to continue to the patient page
        </Box>
        <Box
          sx={{
            width: "100%",
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            label="Close"
            onClick={() => setShowRestrictedSetupDialog(false)}
          />
        </Box>
      </PopupDialog>
      <PatientCreateEdit
        restricted={patient.restricted}
        nhsNumber={patient.nhsNumber ?? undefined}
        refetchAndRedirect={refetchAndRedirect}
        setShowUpdateSuccessDialog={setShowUpdateSuccessDialog}
        mode={mode ?? "edit"}
        existingPatientId={patientId}
        expectedVersion={patient.version}
        exitPatientCreate={() => {
          if (patientId) {
            return mode === "editPatientDialog"
              ? navigate(routeFns.formDraftsComplete(formDraftId!, patientId!))
              : navigate(routeFns.patientHome(patientId));
          }
        }}
        initialState={{
          givenName: patient.name.given,
          familyNameAndAddress: {
            name: patient.name.family,
            postalCode: patient.address.postalCode,
            address: patient.address.address,
            isConfirmed: true,
          },
          isPatientDateOfBirth: patient.dateOfBirth ? true : false,
          dateOfBirth: patient.dateOfBirth ?? null,
        }}
      />
    </Box>
  );
}

export function PatientEditPage({ mode, refetchDraft }: PatientEditProps) {
  const { patientId } = useParams();
  const { t } = useTranslation();

  const [showUpdateSuccessDialog, setShowUpdateSuccessDialog] =
    React.useState(false);
  const navigate = useNavigate();

  return (
    <>
      <WithPatient patientId={patientId}>
        {(patient) => {
          return (
            <PatientEditInner
              patient={patient}
              mode={mode}
              refetchDraft={refetchDraft}
              setShowUpdateSuccessDialog={setShowUpdateSuccessDialog}
            />
          );
        }}
      </WithPatient>
      {showUpdateSuccessDialog && mode !== "editPatientDialog" && (
        <BannerDialog
          title={t("pages.patientEditCreate.updateSuccess")}
          message={t("pages.patientEditCreate.updateSuccessDialogMessage")}
          onClose={() => {
            return navigate(routeFns.patientHome(patientId!));
          }}
        />
      )}
    </>
  );
}
