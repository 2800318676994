import { BasePatientDemographics, ExternalPatientLink } from "@aspire/common";
import { Box, Stack } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/design-system/index.js";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection.js";
import { ConfirmationModal } from "~/pages/ConfirmationModal.js";

import {
  Choice,
  obviousChoice,
  PatientRecords,
  SystemType,
} from "./helpers.js";
export type { SystemType } from "./helpers.js";

import {
  ChoiceGroupProps,
  ChoiceLayoutDesktop,
  ChoiceLayoutMobile,
} from "./ChoiceLayout.js";

export type ReviewExternalPatientRecordProps = {
  externalSystemType: ExternalPatientLink["externalSystemType"];
  patientRecords: PatientRecords;

  confirmButtonText: string;
  onConfirm: (chosen: {
    // This matches the structure of BasePatientDemographics
    name: { given: SystemType; family: SystemType };
    address: { address: SystemType; postalCode: SystemType };
    dateOfBirth: SystemType;
  }) => Promise<boolean>;

  cancelButtonText: string;
  onCancel: () => void;
  disabled?: boolean;
};

export const ReviewExternalPatientRecord = ({
  externalSystemType,
  patientRecords,
  confirmButtonText,
  onConfirm,
  cancelButtonText,
  onCancel,
  disabled,
}: ReviewExternalPatientRecordProps) => {
  const { t } = useTranslation();

  const systemTypeMap: Record<SystemType, string> = {
    eMHA: "eMHA",
    Other: externalSystemType === "rio" ? "Rio" : "PDS (Spine)",
  };

  // This is how we surface the final confirmation dialog
  const [confirmDialogFn, setConfirmDialogFn] = React.useState<
    | {
        confirmDialogFn: () => void;
        message: string;
      }
    | undefined
  >(undefined);

  // Filter out undefined patient records
  const definedPatientRecords = useMemo(
    () =>
      Object.entries(patientRecords).filter(
        (
          patientRecord,
        ): patientRecord is [SystemType, BasePatientDemographics] =>
          patientRecord[1] !== undefined,
      ),
    [patientRecords],
  );

  // This is where we store the user's choices
  const forenameChoices = useMemo(
    () =>
      definedPatientRecords.map(([system, patient]) => {
        return {
          system: system as SystemType,
          type: "forename",
          value: patient.name.given,
        } satisfies Choice;
      }),
    [definedPatientRecords],
  );

  const [selectedForename, setSelectedForename] = useState(
    obviousChoice(forenameChoices),
  );

  const surnameChoices = useMemo(
    () =>
      definedPatientRecords.map(([system, patient]) => {
        return {
          system: system as SystemType,
          type: "surname",
          value: patient.name.family,
        } satisfies Choice;
      }),
    [definedPatientRecords],
  );
  const [selectedSurname, setSelectedSurname] = useState(
    obviousChoice(surnameChoices),
  );

  // As this is an optional field, there may be no DOB in either record
  const dobChoices = useMemo(
    () =>
      definedPatientRecords.map(([system, patient]) => {
        return {
          system: system as SystemType,
          type: "dob",
          value:
            (patient.dateOfBirth ?? undefined) === undefined
              ? t(
                  "components.externalPatientLink.reviewExternalPatientRecord.unknown",
                )
              : dayjs(patient.dateOfBirth).format("DD/MM/YYYY"),
        } satisfies Choice;
      }),
    [],
  );
  const [selectedDOB, setSelectedDOB] = useState(obviousChoice(dobChoices));

  const addressChoices = useMemo(
    () =>
      definedPatientRecords.map(([system, patient]) => {
        // Format the address so that it line breaks nicely.
        // 1) Split it into fields on all of the commas.
        // 2) Within those fields, use non-breaking spaces to prevent line breaks.
        // 3) Join the fields back together with commas.
        // We have historically seen problems with people copying and pasting
        // content containing non-breaking spaces, so all of the display code
        // in ChoiceLayout uses CSS "user-select: none;" to stop this happening.
        const fields: string[] = [
          ...patient.address.address.split(","),
          patient.address.postalCode,
        ]
          .map((field) => field.trim())
          .map((field) =>
            field.replace(
              / /g, // All space characters
              "\u00A0", // Non-breaking space
            ),
          );
        const value = fields.join(", ");

        return {
          system: system as SystemType,
          type: "address",
          value: value,
        } satisfies Choice;
      }),
    [definedPatientRecords],
  );
  const [selectedAddress, setSelectedAddress] = useState(
    obviousChoice(addressChoices),
  );

  const choiceGroups: ChoiceGroupProps[] = [
    {
      label: t(
        "components.externalPatientLink.reviewExternalPatientRecord.forename",
      ),
      choices: forenameChoices,
      chosen: selectedForename,
      setChosen: setSelectedForename,
    },
    {
      label: t(
        "components.externalPatientLink.reviewExternalPatientRecord.surname",
      ),
      choices: surnameChoices,
      chosen: selectedSurname,
      setChosen: setSelectedSurname,
    },
    {
      label: t(
        "components.externalPatientLink.reviewExternalPatientRecord.dob",
      ),
      choices: dobChoices,
      chosen: selectedDOB,
      setChosen: setSelectedDOB,
    },
    {
      label: t(
        "components.externalPatientLink.reviewExternalPatientRecord.address",
      ),
      choices: addressChoices,
      chosen: selectedAddress,
      setChosen: setSelectedAddress,
    },
  ];

  const allChosen = [
    selectedForename,
    selectedSurname,
    selectedDOB,
    selectedAddress,
  ].every((choice) => choice !== undefined);

  const onConfirmCallback = useCallback(() => {
    // All values must be selected in order for this to make sense
    if (!allChosen) {
      throw new Error("Cannot confirm without all values chosen");
    }

    onConfirm({
      name: { given: selectedForename!, family: selectedSurname! },
      address: { address: selectedAddress!, postalCode: selectedAddress! },
      dateOfBirth: selectedDOB!,
    });
  }, [
    allChosen,
    onConfirm,
    selectedAddress,
    selectedDOB,
    selectedForename,
    selectedSurname,
  ]);

  const onShowConfirmDialog = useCallback(() => {
    // It is only an update if there are records to compare
    const isUpdate = definedPatientRecords.length > 1;

    if (isUpdate) {
      // Gather the eMHA patient details for a confirmation dialog
      const originalRecord: BasePatientDemographics | undefined =
        definedPatientRecords
          .filter(([systemType, _patient]) => systemType === "eMHA")
          .map(([_systemType, patient]) => patient)?.[0];
      const givenName = originalRecord?.name?.given;
      const familyName = originalRecord?.name?.family;

      // Show a confirmation dialog
      setConfirmDialogFn({
        message: t("pages.patientEditCreate.editPatientConfirmation", {
          givenName: givenName,
          familyName: familyName,
        }),
        confirmDialogFn: () => {
          setConfirmDialogFn(undefined);
          onConfirmCallback();
        },
      });
    } else {
      // No confirmation required if it is a new patient
      setConfirmDialogFn(undefined);
      onConfirmCallback();
    }
  }, [definedPatientRecords, onConfirmCallback, t]);

  // We use a different layout for mobile and desktop
  const { isMobileView } = useScreenDetection();
  const RadioLayout = isMobileView ? ChoiceLayoutMobile : ChoiceLayoutDesktop;

  return (
    <Stack data-testid="review-external-patient-record">
      {confirmDialogFn && (
        <ConfirmationModal
          message={confirmDialogFn.message}
          confirmFn={confirmDialogFn.confirmDialogFn}
          closeFn={() => setConfirmDialogFn(undefined)}
        />
      )}

      <Stack gap="1rem">
        <RadioLayout
          choiceGroups={choiceGroups}
          systemTypeMap={systemTypeMap}
        />
        <Stack gap="1rem" direction="row" justifyContent={"center"}>
          <Button
            disabled={disabled}
            variant="outlined"
            label={cancelButtonText}
            onClick={onCancel}
            testId="cancel-button"
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            label={confirmButtonText}
            disabled={disabled || !allChosen}
            onClick={onShowConfirmDialog}
            testId="confirm-button"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

////

export type ReviewNewExternalPatientRecordProps = Omit<
  ReviewExternalPatientRecordProps,
  "confirmButtonText" | "cancelButtonText" | "patientRecords"
> & {
  patientRecords: Record<"Other", BasePatientDemographics>;
  externalSystemType: ExternalPatientLink["externalSystemType"];
};

export const ReviewNewExternalPatientRecord = ({
  externalSystemType,
  patientRecords,
  onCancel,
  onConfirm,
  disabled,
}: ReviewNewExternalPatientRecordProps) => {
  const { t } = useTranslation();

  const confirmButtonText = t(
    "components.externalPatientLink.reviewNewExternalPatientRecord.confirm",
  );
  const cancelButtonText = t(
    "components.externalPatientLink.reviewNewExternalPatientRecord.cancel",
  );

  // Commented out as types protect us at the moment
  // We cannot have a local record
  // if (patientRecords.eMHA !== undefined) {
  //   throw new Error(
  //     "Cannot create a new patient record when one already exists",
  //   );
  // }

  // Commented out as types protect us at the moment
  // We must have exactly one external record
  // if (patientRecords.Rio === undefined) {
  //   throw new Error(
  //     "We require exactly one external patient record to create a new patient record",
  //   );
  // }

  return (
    <ReviewExternalPatientRecord
      externalSystemType={externalSystemType}
      disabled={disabled}
      cancelButtonText={cancelButtonText}
      onCancel={onCancel}
      confirmButtonText={confirmButtonText}
      onConfirm={onConfirm}
      patientRecords={{ eMHA: undefined, ...patientRecords }}
    />
  );
};

////

export type ReviewExistingExternalPatientRecordProps = Omit<
  ReviewExternalPatientRecordProps,
  "confirmButtonText" | "cancelButtonText" | "patientRecords"
> & {
  patientRecords: Record<SystemType, BasePatientDemographics>;
  externalSystemType: ExternalPatientLink["externalSystemType"];
};

export const ReviewExistingExternalPatientRecord = ({
  externalSystemType,
  patientRecords,
  onCancel,
  onConfirm,
  disabled,
}: ReviewExistingExternalPatientRecordProps) => {
  const { t } = useTranslation();

  const confirmButtonText = t(
    "components.externalPatientLink.reviewExistingExternalPatientRecord.confirm",
  );
  const cancelButtonText = t(
    "components.externalPatientLink.reviewExistingExternalPatientRecord.cancel",
  );

  // Commented out as types protect us at the moment
  // We must have a local record
  // if (patientRecords.eMHA === undefined) {
  //   throw new Error("Cannot update a patient without an existing record");
  // }

  // Commented out as types protect us at the moment
  // We must have at least one external record
  // if (patientRecords.Rio === undefined) {
  //   throw new Error(
  //     "We require at least one external patient record to update an existing patient record",
  //   );
  // }

  return (
    <ReviewExternalPatientRecord
      externalSystemType={externalSystemType}
      disabled={disabled}
      cancelButtonText={cancelButtonText}
      onCancel={onCancel}
      confirmButtonText={confirmButtonText}
      onConfirm={onConfirm}
      patientRecords={patientRecords}
    />
  );
};
