import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { FormikConfig, FormikProps } from "formik";
import React from "react";
import { HorizontalLine } from "~/components/form/HorizontalLine.js";
import {
  CollectCriteriaDemographics,
  CollectCriteriaDemographicsProps,
} from "./CollectCriteriaDemographics.js";
import {
  CollectCriteriaNhsNumber,
  CollectCriteriaNhsNumberProps,
} from "./CollectCriteriaNhsNumber.js";
import {
  CollectCriteriaPdsDemographics,
  CollectCriteriaPdsDemographicsProps,
} from "./CollectCriteriaPdsDemographics.js";
import { FooterProps } from "./Footer.js";

export type CollectCriteria = {
  canSearchByNhsNumber: boolean;
  canSearchByPdsDemographics: boolean;
  canUsePhoneticNameMatch: boolean;
  onSubmitPdsDemographics: CollectCriteriaPdsDemographicsProps["onSubmit"];
  onSubmitNhsNumber: CollectCriteriaNhsNumberProps["onSubmit"];
  onSubmitDemographics: CollectCriteriaDemographicsProps["onSubmit"];
  children?:
    | ((args: Pick<FooterProps, "onClear">) => React.ReactNode)
    | React.ReactNode;
  criteriaType: "nhs-number" | "demographics" | "pds-demographics";
  setCriteriaType: (
    criteriaType: "nhs-number" | "demographics" | "pds-demographics",
  ) => void;
  disabled?: boolean;
};

// Turn a strongly-typed Formik<T> render function into
// a loosely-typed render function for consumers who don't
// care about field-specific values.
function mappedChildren<T>(
  children: NonNullable<CollectCriteria["children"]>,
): FormikConfig<T>["children"] {
  if (typeof children !== "function") {
    return children as React.ReactNode;
  } else {
    return (args: FormikProps<T>) => {
      return children({
        onClear: () => {
          args.resetForm();
          args.setErrors({});
        },
      });
    };
  }
}

export const CollectCriteria = ({
  canSearchByPdsDemographics,
  canSearchByNhsNumber,
  canUsePhoneticNameMatch: canUsePhoneticNameMatch,
  onSubmitNhsNumber,
  onSubmitDemographics,
  onSubmitPdsDemographics,
  children,
  criteriaType,
  setCriteriaType,
  disabled,
}: CollectCriteria) => {
  return (
    <Stack>
      <FormControl>
        <RadioGroup
          value={criteriaType}
          onChange={(event) => {
            if (
              ["nhs-number", "demographics", "pds-demographics"].includes(
                event.target.value,
              )
            ) {
              setCriteriaType(
                event.target.value as
                  | "nhs-number"
                  | "demographics"
                  | "pds-demographics",
              );
            }
          }}
        >
          {canSearchByNhsNumber && (
            <FormControlLabel
              data-testid="radio-nhs-number"
              value="nhs-number"
              control={<Radio />}
              label="Search by NHS Number"
            />
          )}
          <FormControlLabel
            data-testid="radio-demographics"
            value="demographics"
            control={<Radio />}
            label="Search by Demographics"
          />
          {canSearchByPdsDemographics && (
            <FormControlLabel
              data-testid="radio-demographics"
              value="pds-demographics"
              control={<Radio />}
              label="Search PDS (by Demographics)"
            />
          )}
          <HorizontalLine />
          {criteriaType === "nhs-number" && canSearchByNhsNumber && (
            <CollectCriteriaNhsNumber
              onSubmit={onSubmitNhsNumber}
              disabled={disabled}
            >
              {
                // Extra children which will be added to the form.
                // Useful for injecting a submit button for testing.
                children && mappedChildren(children)
              }
            </CollectCriteriaNhsNumber>
          )}
          {criteriaType === "demographics" && (
            <CollectCriteriaDemographics
              canUsePhoneticNameMatch={canUsePhoneticNameMatch}
              onSubmit={onSubmitDemographics}
              disabled={disabled}
            >
              {
                // Extra children which will be added to the form.
                // Useful for injecting a submit button for testing.
                children && mappedChildren(children)
              }
            </CollectCriteriaDemographics>
          )}
          {criteriaType === "pds-demographics" &&
            canSearchByPdsDemographics && (
              <CollectCriteriaPdsDemographics
                onSubmit={onSubmitPdsDemographics}
                disabled={disabled}
              >
                {
                  // Extra children which will be added to the form.
                  // Useful for injecting a submit button for testing.
                  children && mappedChildren(children)
                }
              </CollectCriteriaPdsDemographics>
            )}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
