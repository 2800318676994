import { ExtendedPatient } from "@aspire/common";
import { Box } from "@mui/material";
import { isEmpty } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BannerList,
  Dropdown,
  PopupDialog,
  PopupDialogTitle,
  TextField,
} from "~/components/design-system/index.js";
import { HorizontalLine } from "~/components/form/index.js";
import { PatientSearch } from "../PatientSearch.js";
import { ConfirmMerge } from "./ConfirmMerge.js";

import { useParams } from "react-router";
import { usePatientTimeline } from "~/hooks/apiCalls.js";
import { mergeReasonValues } from "./mergeReasons.js";
import { hasInProgressWork } from "./util.js";

export function PatientMergeDialog({
  closeMergeDialog,
}: {
  closeMergeDialog: () => void;
}) {
  const { patientId } = useParams();
  const { patientTimeline } = usePatientTimeline({
    patientId: patientId,
  });

  const { t } = useTranslation();

  const [selectedPatient, setSelectedPatient] =
    React.useState<ExtendedPatient | null>(null);

  const [reasonValueDropdown, setReasonValueDropdown] = React.useState(null);

  const [reasonDescription, setReasonDescription] = React.useState<
    string | undefined
  >(undefined);

  const isDropdownValueOther = reasonValueDropdown === "other";

  const showPatientSearch =
    (isDropdownValueOther && reasonDescription) ||
    (!isDropdownValueOther && reasonValueDropdown);

  const hasFormInProgressForms = hasInProgressWork(patientTimeline);

  return (
    <>
      {isEmpty(selectedPatient) ? (
        <>
          <PopupDialog open={true} onClose={closeMergeDialog}>
            <PopupDialogTitle
              titleText={`Merge Patient ${
                patientTimeline?.patient.name.given || ""
              } ${patientTimeline?.patient.name.family || ""}`}
              closeDialog={closeMergeDialog}
            />
            <Box sx={{ mb: 2 }}>
              <Banner
                bannerType={BannerList.INFO}
                body={[
                  `${t(
                    "components.patientMergeDialog.mergeConfirmationMessageOne",
                  )}`,
                  `${t(
                    "components.patientMergeDialog.mergeConfirmationMessageTwo",
                  )}`,
                ]}
              />
            </Box>
            {hasFormInProgressForms && (
              <Box sx={{ mb: 2 }}>
                <Banner
                  bannerType={BannerList.WARNING}
                  title={t(
                    "components.patientMergeDialog.assessmentInProgressMessage",
                  )}
                />
              </Box>
            )}
            <Box sx={{ my: 2 }}>
              <Dropdown
                label={t("components.patientMergeDialog.reasonWhyMerge")}
                selectedValue={reasonValueDropdown}
                name={"invite"}
                values={mergeReasonValues}
                onChange={(e) => {
                  setReasonValueDropdown(e);
                }}
              />
              {reasonValueDropdown === "other" && (
                <TextField
                  name={"reasonDescription"}
                  label={t(
                    "components.patientMergeDialog.reasonDescriptionText",
                  )}
                  onChange={(e) => setReasonDescription(e)}
                  value={reasonDescription || ""}
                  useFullWidth={true}
                />
              )}
            </Box>
            {showPatientSearch && (
              <>
                <HorizontalLine />
                <PatientSearch
                  patient={patientTimeline?.patient}
                  setPatientCreateState={() => {}}
                  isPatientMerge
                  setSelectedPatient={setSelectedPatient}
                />
              </>
            )}
          </PopupDialog>
        </>
      ) : (
        <>
          <ConfirmMerge
            currentPatient={patientTimeline?.patient!}
            selectedPatient={selectedPatient!}
            closeMergeDialog={closeMergeDialog}
            reason={
              mergeReasonValues.find(
                (item) => item.value === reasonValueDropdown,
              )?.label || ""
            }
            goBack={() => setSelectedPatient(null)}
            reasonDescription={reasonDescription}
          />
        </>
      )}
    </>
  );
}
