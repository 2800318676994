import { MatchedPatient } from "@aspire/common";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { PatientCardExtended } from "~/components/design-system/index.js";

export type SearchResultData = {
  type: "existing-patient";
  patient: MatchedPatient;
};

type SelectedProps = {
  selected: boolean;
  setSelected: () => void;
};

export type SearchResultProps = SearchResultAspireLocalPatientProps &
  SelectedProps;

export const SearchResult = ({
  searchResult,
  selected,
  setSelected,
}: SearchResultProps) => {
  switch (searchResult.type) {
    case "aspire-patient":
      return (
        <SearchResultAspireLocalPatient
          searchResult={searchResult}
          selected={selected}
          setSelected={setSelected}
        />
      );
    default: {
      console.error("Unknown search result type", searchResult);
      return null;
    }
  }
};

/**
 * A SearchResult for an Aspire Patient
 */
export type SearchResultAspireLocalPatientProps = {
  searchResult: { type: "aspire-patient"; patient: MatchedPatient };
};

export const SearchResultAspireLocalPatient = ({
  searchResult,
  selected,
  setSelected,
}: SearchResultAspireLocalPatientProps & SelectedProps) => {
  const patientAge = useAgeFromDateOfBirth(
    searchResult.patient.patientDemographics.dateOfBirth,
  );

  return (
    <PatientCardExtended
      nhsNumber={searchResult.patient.patientDemographics.nhsNumber}
      patientName={searchResult.patient.patientDemographics.name}
      address={searchResult.patient.patientDemographics.address ?? ""}
      patientDateOfBirth={
        searchResult.patient.patientDemographics.dateOfBirth ?? "Unknown"
      }
      patientAge={patientAge}
      isSelected={selected}
      setIsSelected={() => setSelected()}
    />
  );
};

const useAgeFromDateOfBirth = (dateOfBirth: string | null | undefined) => {
  const { t } = useTranslation();

  return dateOfBirth
    ? dayjs().diff(dateOfBirth, "year").toString()
    : t("common.unknown");
};
