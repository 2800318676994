import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { api } from "~/api.js";
import {
  Banner,
  BannerList,
  Dropdown,
  FormTitle,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/FormFooter.js";
import { CompactedPatientBanner } from "~/components/layout/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { usePatientTimeline } from "~/hooks/apiCalls.js";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection.js";
import { routeFns } from "~/routes.js";
import { mergeReasonValues } from "../PatientIndex/PatientMerge/mergeReasons.js";
import { hasInProgressWork } from "../PatientIndex/PatientMerge/util.js";

type PatientMergeConfirmationProps = {};

export function PatientMergeConfirmation(props: PatientMergeConfirmationProps) {
  const userContext = React.useContext(LoggedInUserContext);
  const hasPatientMergeAccess =
    userContext?.user?.hasPatientMergeAccess ?? false;

  const { fromPatientId, toPatientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobileView } = useScreenDetection();
  const [animateArrow, setAnimateArrow] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const theme = useTheme();

  // Redirect away if merge v2 not enabled
  React.useEffect(() => {
    if (
      userContext &&
      !(
        userContext.user?.sessionOrganisationConfiguration?.mergeV2Enabled &&
        hasPatientMergeAccess
      )
    ) {
      renderErrorToast({
        message: "Patient merge v2 is not enabled for this user",
      });
      navigate(routeFns.home());
    }
  }, [userContext]);

  React.useEffect(() => {
    setAnimateArrow(true);
    return () => setAnimateArrow(false);
  }, []);

  const {
    patientTimeline: fromPatientTimeline,
    patientTimelineCompleteLoading: fromPatientTimelineLoading,
  } = usePatientTimeline({
    patientId: fromPatientId,
  });

  const {
    patientTimeline: toPatientTimeline,
    patientTimelineCompleteLoading: toPatientTimelineLoading,
  } = usePatientTimeline({
    patientId: toPatientId,
  });

  const [reasonValueDropdown, setReasonValueDropdown] = React.useState(null);

  const [reasonDescription, setReasonDescription] = React.useState<
    string | undefined
  >(undefined);

  // Validate merge criteria
  React.useEffect(() => {
    if (!fromPatientId || !toPatientId || fromPatientId === toPatientId) {
      renderErrorToast({ message: "Invalid patient merge request" });
      navigate(routeFns.home());
    }
  }, [fromPatientId, toPatientId]);

  const submitMerge = React.useCallback(() => {
    if (fromPatientId && toPatientId && reasonValueDropdown) {
      api.patients
        .merge({
          currentPatientId: fromPatientId,
          targetPatientId: toPatientId,
          reason:
            mergeReasonValues.find((item) => item.value === reasonValueDropdown)
              ?.label || "",
          reasonDescription,
        })
        .then((result) => {
          if (result.status === 204) {
            const selectedPatientName = toPatientTimeline?.patient.name;
            renderSuccessToast({
              message: `Successfully merged ${
                fromPatientTimeline?.patient.name.given || ""
              }, ${fromPatientTimeline?.patient.name.family} into ${
                selectedPatientName?.given
              }, ${selectedPatientName?.family}`,
            });
            navigate(routeFns.patientHome(toPatientId!));
          } else {
            renderErrorToast({
              message: `Failed to merge - ${result.data.reason}`,
            });
          }
        })
        .catch((error) => {
          renderErrorToast({
            message: `Failed to merge - ${error.message}`,
          });
        });
    }
  }, [
    fromPatientId,
    toPatientId,
    reasonValueDropdown,
    reasonDescription,
    fromPatientTimeline,
    toPatientTimeline,
  ]);

  const fromPatientNhsNumber =
    fromPatientTimeline?.patient.nhsNumber ?? undefined;
  const toPatientNhsNumber = toPatientTimeline?.patient.nhsNumber ?? undefined;

  const isLoading = fromPatientTimelineLoading || toPatientTimelineLoading;

  // Validate returned patients
  React.useEffect(() => {
    if (!isLoading) {
      if (!fromPatientTimeline || !toPatientTimeline) {
        renderErrorToast({
          message: "Invalid patient merge request - patients not found",
        });
        navigate(routeFns.home());
      } else if (
        fromPatientTimeline?.patient.id === toPatientTimeline?.patient.id
      ) {
        renderErrorToast({
          message: "Invalid patient merge request - same patient",
        });
        navigate(routeFns.home());
      }
    }
  }, [isLoading, fromPatientTimeline, toPatientTimeline]);

  const isValidReason =
    reasonValueDropdown &&
    !(reasonValueDropdown === "other" && !reasonDescription);

  const hasFormInProgressForms =
    fromPatientTimeline && hasInProgressWork(fromPatientTimeline);

  return (
    <>
      {isLoading || !fromPatientTimeline || !toPatientTimeline ? (
        <CircularProgress />
      ) : (
        <Stack sx={{ maxWidth: "70rem" }}>
          <FormTitle
            useReducedTopPadding={true}
            hasTitleBottomMargin={false}
            titleText={t("components.patientMergeDialog.confirmYourAction")}
          />
          <Box sx={{ mb: 2 }}>
            <Banner
              bannerType={BannerList.INFO}
              body={[
                `${t(
                  "components.patientMergeDialog.mergeConfirmationMessageOne",
                )}`,
                `${t(
                  "components.patientMergeDialog.mergeConfirmationMessageTwo",
                )}`,
              ]}
            />
          </Box>
          {fromPatientNhsNumber &&
            toPatientNhsNumber &&
            fromPatientNhsNumber !== toPatientNhsNumber && (
              <Box sx={{ mb: 2 }}>
                <Banner
                  bannerType={BannerList.ERROR}
                  title={"NHS Numbers do not match"}
                  body={
                    "Merging two patients with different NHS numbers is very likely incorrect and may result in unexpected behaviour. Please review carefully before proceeding."
                  }
                />
              </Box>
            )}
          {hasFormInProgressForms && (
            <Box sx={{ mb: 2 }}>
              <Banner
                bannerType={BannerList.WARNING}
                title={t(
                  "components.patientMergeDialog.assessmentInProgressMessage",
                )}
              />
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            flexDirection={isMobileView ? "column" : "row"}
            mb={2}
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Typography fontWeight="bold" sx={{ my: 1, ml: 1.875 }}>
                {t("components.patientMergeDialog.patientRemoved")}
              </Typography>
              <Box sx={{ border: "1px solid #FF3D00", borderRadius: "5px" }}>
                <CompactedPatientBanner
                  patient={fromPatientTimeline!.patient}
                  nhsNumber={fromPatientNhsNumber ?? undefined}
                  isHidden
                />
              </Box>
            </Box>
            {isMobileView ? (
              <KeyboardDoubleArrowDown
                fontSize="large"
                sx={{
                  mx: 2,
                  opacity: animateArrow ? 1 : 0,
                  transition:
                    "opacity 1s ease-in-out, transform 1s ease-in-out",
                  transform: animateArrow
                    ? "translateX(0)"
                    : "translateX(-50px)",
                }}
              />
            ) : (
              <>
                <KeyboardDoubleArrowRight
                  fontSize="large"
                  sx={{
                    mx: 2,
                    opacity: animateArrow ? 1 : 0,
                    transition:
                      "opacity 1s ease-in-out, transform 1s ease-in-out",
                    transform: animateArrow
                      ? "translateX(0)"
                      : "translateX(-50px)",
                  }}
                />
              </>
            )}

            <Box display="flex" flexDirection="column" width="100%">
              <Typography fontWeight="bold" sx={{ my: 1, ml: 1.875 }}>
                {t("components.patientMergeDialog.remainPrimaryPatient")}
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.common.limeGreen}`,
                  borderRadius: "5px",
                }}
              >
                <CompactedPatientBanner
                  patient={toPatientTimeline!.patient}
                  nhsNumber={toPatientNhsNumber}
                  isHidden
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Dropdown
              label={t("components.patientMergeDialog.reasonWhyMerge")}
              selectedValue={reasonValueDropdown}
              name={"invite"}
              values={mergeReasonValues}
              onChange={(e) => {
                setReasonValueDropdown(e);
                setReasonDescription(undefined);
              }}
            />
            {reasonValueDropdown === "other" && (
              <TextField
                name={"reasonDescription"}
                label={t("components.patientMergeDialog.reasonDescriptionText")}
                onChange={(e) => setReasonDescription(e)}
                value={reasonDescription || ""}
                useFullWidth={true}
              />
            )}
          </Box>
          {isValidReason && (
            <>
              <Box sx={{ mb: 2 }}>
                <Banner
                  bannerType={BannerList.WARNING}
                  title={t("components.patientMergeDialog.mergeThesePatients")}
                />
              </Box>

              <FormFooterSection
                onSave={submitMerge}
                onCancel={() => navigate(routeFns.home())}
                saveLabel={t("components.patientMergeDialog.confirmMerge")}
                disableDiscard={true}
                hideDiscard={true}
                disableSubmit={isSubmitting || !isValidReason}
              />
            </>
          )}
        </Stack>
      )}
    </>
  );
}
