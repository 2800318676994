import dayjs from "dayjs";

export const ageRangeToDateRange = (ages: string | null) => {
  const parsedAges = ages === null ? null : JSON.parse(ages);
  if (
    parsedAges === null ||
    (parsedAges.min === undefined && parsedAges.max === undefined)
  ) {
    return null;
  }

  const startDate = dayjs()
    .subtract(parsedAges.max ?? 120, "years")
    .format("YYYY-MM-DD");
  const endDate = dayjs()
    .subtract(parsedAges.min ?? 0, "years")
    .format("YYYY-MM-DD");

  return [startDate, endDate];
};
