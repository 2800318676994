import {
  Container,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { ValidateShareCodeV1Response } from "@thalamos/common";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "~/api.js";
import {
  Banner,
  BannerList,
  Button,
  renderErrorToast,
} from "~/components/design-system/index.js";

export function EMHFormsShareCodePage({}: {}) {
  const [searchParams] = useSearchParams();
  const shareCodeFromParams = searchParams.get("shareCode");

  const [shareCode, setShareCode] = React.useState(shareCodeFromParams);
  const [submitting, setSubmitting] = React.useState(false);

  const [validatedShareCodeResponse, setValidatedShareCodeResponse] =
    React.useState<Omit<ValidateShareCodeV1Response, "incidentId"> | null>(
      null,
    );

  const [selectedPos, setSelectedPos] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Stack maxWidth={400} minWidth={400} gap={"2rem"}>
        <Input
          value={shareCode}
          onChange={(e) => setShareCode(e.target.value)}
        />
        <Button
          disabled={submitting || !shareCode || !!validatedShareCodeResponse}
          onClick={async () => {
            setSubmitting(true);
            setError(null);
            try {
              const response = await api.vision.validateShareCode(shareCode!);

              if (response.status === 200) {
                setValidatedShareCodeResponse(response.data);
              } else {
                setError((response.data as any).reason);
              }
            } catch (e) {
              setError((e as any).message);
            } finally {
              setSubmitting(false);
            }
          }}
          label="Submit share code"
        />
        {error && <Banner bannerType={BannerList.ERROR} title={error} />}

        {validatedShareCodeResponse && (
          <Stack sx={{ mt: "2rem" }} gap="1rem">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Place of Safety
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPos}
                label="Age"
                onChange={(e) => setSelectedPos(e.target.value as string)}
              >
                {validatedShareCodeResponse.placesOfSafety.map((pos) => (
                  <MenuItem value={pos.id}>{pos.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <Button
              label="Go to Login & View Form"
              disabled={!email || !name || !selectedPos || submitting}
              onClick={async () => {
                const selectedPosObject =
                  validatedShareCodeResponse.placesOfSafety.find(
                    (pos) => pos.id === selectedPos,
                  )!;

                setSubmitting(true);
                try {
                  const response = await api.vision.accessRequest({
                    email: email!,
                    name: name!,
                    shareCode: shareCode!,
                    placeOfSafety: {
                      id: selectedPosObject.id,
                      name: selectedPosObject.name,
                      address: selectedPosObject.address,
                      odsCode: selectedPosObject.odsCode,
                    },
                  });

                  if (response.status === 200) {
                    window.location.href = response.data.redirectUrl;
                  } else {
                    renderErrorToast({
                      message: (response.data as any).reason,
                    });
                  }
                } catch (e) {
                  renderErrorToast({ message: (e as any).message });
                } finally {
                  setSubmitting(false);
                }
              }}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
