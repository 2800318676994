import {
  ExtendedPatient,
  isoDateString,
  Patient,
  PatientIndexSearchResult,
} from "@aspire/common";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { Formik } from "formik";
import { omit } from "lodash-es";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { boolean, InferType, object, string } from "yup";
import {
  Banner,
  BannerList,
  Button,
  Dropdown,
  FormLabel,
  FormTitle,
  HelperText,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  Stepper,
  TextField,
} from "~/components/design-system/index.js";
import {
  DateFormField,
  FormFooterSection,
  HorizontalLine,
  TextboxFormField,
} from "~/components/form/index.js";
import {
  Container,
  StickyContainer,
} from "~/components/layout/styleWrappers.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { api } from "../../api.js";
import { useScreenDetection } from "../../hooks/ScreenDetection/useScreenDetection.js";
import { routeFns } from "../../routes.js";
import { DefaultPageProps } from "../defaultProps.js";
import { FieldProps } from "../FieldProps.js";
import { PatientSearch as PatientPageV2 } from "../PatientSearchV2/PatientSearch.js";
import { PatientCreateEdit } from "./PatientCreateEdit.js";
import { PatientSearchResult } from "./PatientSearchResult.js";

export const patientSearchSchema = object({
  type: string()
    .required()
    .oneOf(["nhs-number", "demographics"])
    .default("demographics"),
  givenName: string().default(null).nullable(),
  familyName: string()
    .nullable()
    .default(null)
    .when("type", {
      is: "demographics",
      then: (s) => s.required("Please enter patient's surname"),
      otherwise: (s) => s.nullable(),
    }),
  dateOfBirthCheckbox: boolean().nullable().default(null),
  dateOfBirth: string()
    .nullable()
    .default(null)
    .when("dateOfBirthCheckbox", {
      is: true,
      then: () => isoDateString.required("Please enter a valid time / date"),
      otherwise: (s) => s.nullable(),
    }),
});

export type PatientSearchSchema = InferType<typeof patientSearchSchema>;

export const rioPatientSearchSchema = object({
  nhsNumber: string()
    .transform((value) => value.replace(/\s/g, "")) // Removes whitespace
    .matches(/[0-9]{10}/, "Enter a 10-digit NHS Number")
    .default(""),
});
export type RioPatientSearchSchema = InferType<typeof rioPatientSearchSchema>;

export function PatientSearch({
  setPatientCreateState,
  isPatientMerge,
  setSelectedPatient,
  patient,
}: {
  setPatientCreateState: (values: null | PatientSearchSchema) => void;
  isPatientMerge?: boolean;
  setSelectedPatient?: (params: ExtendedPatient | null) => void;
  patient?: Patient;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const userContext = useContext(LoggedInUserContext);
  const user = userContext?.user!;

  const navigate = useNavigate();

  const [patientSearchResult, setPatientSearchResult] =
    useState<PatientIndexSearchResult | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedHasDob, setSelectedHasDob] = useState(false);

  const { isMobileView } = useScreenDetection();

  const [activeStep, setActiveStep] = useState(0);

  const [showReasoningDialog, setShowReasoningDialog] = useState(false);

  const dateRange = [
    {
      label: t("pages.patientSearch.dateRange.underAge18"),
      value: "18",
    },
    {
      label: t("pages.patientSearch.dateRange.age18To39"),
      value: "18, 39",
    },
    {
      label: t("pages.patientSearch.dateRange.age40To59"),
      value: "40, 59",
    },
    {
      label: t("pages.patientSearch.dateRange.age60To79"),
      value: "60, 79",
    },
    {
      label: t("pages.patientSearch.dateRange.overAge80"),
      value: "80",
    },
    {
      label: t("pages.patientSearch.dateRange.unknown"),
      value: "UNKNOWN",
    },
  ];

  const getButtonLabel = patientSearchResult
    ? isPatientMerge
      ? t("buttonLabels.merge")
      : t("buttonLabels.continue")
    : t("buttonLabels.search");

  const isSaveButtonDisabled =
    (getButtonLabel === t("buttonLabels.continue") ||
      getButtonLabel === t("buttonLabels.merge")) &&
    !selectedId;

  const patientSearchResultRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    patientSearchResultRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [patientSearchResult]);

  return (
    <>
      {showReasoningDialog && (
        <SearchReasoningDialog
          closeSearchReasoningDialog={() => setShowReasoningDialog(false)}
          selectedId={selectedId}
        />
      )}
      <Formik<PatientSearchSchema>
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={patientSearchSchema}
        initialValues={patientSearchSchema.getDefault()}
        onSubmit={async (values: InferType<typeof patientSearchSchema>) => {
          const getRange = (
            minAge: number | null,
            maxAge: number | null,
          ): [string, string] => {
            const startDate = dayjs()
              .subtract(minAge || 0, "years")
              .format("YYYY-MM-DD");
            const endDate = dayjs()
              .subtract(maxAge || 120, "years")
              .format("YYYY-MM-DD");
            return [startDate, endDate];
          };

          const getDateOfBirthStructure = (dateOfBirth: string | null) => {
            if (selectedHasDob) return dateOfBirth;
            if (dateOfBirth === dateRange.pop()?.value) return null;

            const getNumbersFromString = values.dateOfBirth?.split(",");

            if (!getNumbersFromString) return;
            const dateRangeOne = +getNumbersFromString![0];
            const dateRangeTwo = +getNumbersFromString![1];

            if (dateRangeOne && !dateRangeTwo) {
              if (dateRangeOne >= 80) return getRange(120, dateRangeOne);
              return getRange(dateRangeOne, 1);
            }

            return getRange(dateRangeTwo, dateRangeOne);
          };

          const result = await api.patients.search({
            type: "demographics",
            query: {
              family: values.familyName!,
              birthdate: getDateOfBirthStructure(values.dateOfBirth),
              given: values.givenName || undefined,
              patientId: patient?.id,
            },
          });

          // Necessary so that onblur events fire *before* state is updated with search results.
          // Otherwise they will fire on the next user interaction which causes the form to reset
          // and the search results to disappear.
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }

          if (result.data.matchedPatients.length) setActiveStep(1);
          setPatientSearchResult(result.data);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          setErrors,
          handleBlur,
          setValues: formikSetValues,
          submitForm,
          isSubmitting,
          setFieldValue,
        }) => {
          const setValues = (values: any) => {
            setSelectedId(null);
            setPatientSearchResult(null);
            formikSetValues(values);
          };

          const fieldProps: FieldProps<PatientSearchSchema> = {
            validationSchema: patientSearchSchema,
            context: {},
            values,
            errors,
            handleBlur,
            setFieldTouched,
            touched,
            setValues,
          };

          const resetTouched = () => {
            setFieldTouched("givenName", false);
            setFieldTouched("familyName", false);
            setFieldTouched("dateOfBirth", false);
            setFieldTouched("dateOfBirthCheckbox", false);
          };

          const valuesWithoutType = omit(values, "type");

          const isValuesNullOrEmpty = Object.values(valuesWithoutType).every(
            (e) => e === null || e === "",
          );

          const hasSelectedId = selectedId ? 2 : activeStep;

          return (
            <Box>
              {isPatientMerge ? (
                <FormTitle
                  useReducedTopPadding={true}
                  hasTitleBottomMargin={false}
                  subtitleText={t("pages.patientSearch.mergePatientTitle")}
                />
              ) : (
                <FormTitle
                  useReducedTopPadding={true}
                  hasTitleBottomMargin={false}
                  titleText={t("pages.patientSearch.title")}
                />
              )}

              {!isPatientMerge && (
                <>
                  <HorizontalLine />
                  <StickyContainer issticky={true} top={0}>
                    <Box
                      display="flex"
                      width="100%"
                      sx={{
                        m: {
                          md: `${theme.spacing(0, "auto", 0, "auto")}`,
                        },
                        p: theme.spacing(2),
                        backgroundColor: "white",
                      }}
                    >
                      <Stepper
                        steps={[
                          t("pages.patientSearch.stepper.searchPatient"),
                          t("pages.patientSearch.stepper.selectPatient"),
                          isPatientMerge
                            ? t("pages.patientSearch.stepper.merge")
                            : t("pages.patientSearch.stepper.newForm"),
                        ]}
                        activeStep={hasSelectedId || activeStep}
                      />
                    </Box>
                  </StickyContainer>
                </>
              )}

              <form
                // This handler is required for (at least) dev builds of the frontend on Firefox
                // Please test there before removing.
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                <Container>
                  <TextboxFormField
                    field={{
                      type: "textbox",
                      field: "givenName",
                      label: t("pages.patientSearch.givenName"),
                    }}
                    fieldProps={fieldProps}
                  />

                  <TextboxFormField
                    autoFocus={!isPatientMerge}
                    field={{
                      type: "textbox",
                      field: "familyName",
                      label: t("pages.patientSearch.familyName"),
                    }}
                    fieldProps={fieldProps}
                  />

                  <FormLabel label={t("pages.patientSearch.radioDob")} />
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    defaultValue={null}
                    sx={{ mb: 3, display: "inline-block" }}
                  >
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        control={<Radio sx={{ color: "primary.main" }} />}
                        label={t("common.yes")}
                        value={true}
                        checked={values.dateOfBirthCheckbox === true}
                        onChange={(e: any) => {
                          setSelectedHasDob(true);
                          setValues({
                            ...values,
                            dateOfBirthCheckbox: true,
                            dateOfBirth: null,
                          });
                        }}
                      />

                      <FormControlLabel
                        control={<Radio sx={{ color: "primary.main" }} />}
                        label={t("common.no")}
                        value={false}
                        checked={values.dateOfBirthCheckbox === false}
                        onChange={() => {
                          setSelectedHasDob(false);
                          setValues({
                            ...values,
                            dateOfBirthCheckbox: false,
                            dateOfBirth: null,
                          });
                        }}
                      />
                    </Box>
                    {errors.dateOfBirthCheckbox && (
                      <HelperText
                        errorMessage={errors.dateOfBirthCheckbox as string}
                      />
                    )}
                  </RadioGroup>

                  {values.dateOfBirthCheckbox && (
                    <DateFormField
                      field={{
                        type: "date",
                        field: "dateOfBirth",
                        label: t("pages.patientSearch.dateOfBirth"),
                        maximum: () => dayjs(),
                      }}
                      fieldProps={fieldProps}
                    />
                  )}

                  {values.dateOfBirthCheckbox === false && (
                    <Dropdown
                      label={t("pages.patientSearch.dropdownDateRangeLabel")}
                      errorMessage={errors.dateOfBirth as string}
                      name="dropdown"
                      values={dateRange}
                      selectedValue={values.dateOfBirth || ""}
                      onChange={(value) => {
                        setValues({ ...values, dateOfBirth: value });
                      }}
                    />
                  )}
                </Container>
                {patientSearchResult && (
                  <div ref={patientSearchResultRef}>
                    <PatientSearchResult
                      result={patientSearchResult}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      isPatientMerge={isPatientMerge}
                    />
                  </div>
                )}
                <FormFooterSection
                  customFooterBackgroundColor={theme.palette.common.white}
                  onSave={
                    patientSearchResult
                      ? isPatientMerge
                        ? async () => {
                            return (
                              setSelectedPatient &&
                              setSelectedPatient(
                                patientSearchResult.matchedPatients.find(
                                  (p) => p.id === selectedId,
                                ) ?? null,
                              )
                            );
                          }
                        : async () => {
                            const patientSearchRecordReasonsEnabled =
                              user?.sessionOrganisationConfiguration
                                ?.patientSearchRecordReasonsEnabled;
                            if (patientSearchRecordReasonsEnabled) {
                              setShowReasoningDialog(true);
                            } else {
                              navigate(routeFns.patientHome(selectedId!));
                            }
                          }
                      : submitForm
                  }
                  performSaveActionOnFormSubmit
                  saveLabel={getButtonLabel}
                  saveVariant="contained"
                  secondaryLeftButton={
                    isPatientMerge ? (
                      <></>
                    ) : (
                      <Button
                        disabled={!!selectedId}
                        fullWidth={isMobileView}
                        label={t("buttonLabels.createPatient")}
                        onClick={() => {
                          setPatientCreateState({
                            ...values,
                            dateOfBirth: null,
                          });
                          setValues(patientSearchSchema.getDefault());
                          resetTouched();
                          setSelectedId(null);
                          setPatientSearchResult(null);
                        }}
                      />
                    )
                  }
                  discardLabel={t("buttonLabels.clearSearch")}
                  onCancel={() => {
                    setValues({
                      ...patientSearchSchema.getDefault(),
                      type: values.type,
                    });
                    setActiveStep(0);
                    setErrors({});
                    resetTouched();
                    setSelectedId(null);
                    setPatientSearchResult(null);
                  }}
                  disableSubmit={
                    isSaveButtonDisabled || isValuesNullOrEmpty || isSubmitting
                  }
                />
              </form>
            </Box>
          );
        }}
      </Formik>
    </>
  );
}

export function PatientPage({}: DefaultPageProps) {
  const userContext = React.useContext(LoggedInUserContext);

  return userContext?.user?.sessionOrganisationConfiguration
    ?.searchV2Enabled ? (
    <PatientPageV2 />
  ) : (
    <PatientPageV1 />
  );
}

export function PatientPageV1({}: DefaultPageProps) {
  const { t } = useTranslation();
  const userContext = React.useContext(LoggedInUserContext);

  const rioInstance =
    userContext?.user?.sessionOrganisationConfiguration
      ?.rioInstancesEnabled?.[0];

  // Calculate which tabs to show
  const showEmhaTab = true;
  const showRioTab = rioInstance !== undefined;
  const showTabs = [showEmhaTab, showRioTab].filter((e) => e).length > 1;
  const [selectedTab, setSelectedTab] = useState(0);

  const [patientCreateState, setPatientCreateState] =
    useState<null | PatientSearchSchema>(null);

  const exitPatientCreate = () => {
    setPatientCreateState(null);
  };

  return (
    <>
      {showTabs && (
        <Tabs
          value={selectedTab}
          onChange={(_event, newValue) => setSelectedTab(newValue)}
        >
          {showEmhaTab && (
            <Tab label={t("pages.patientSearch.tabs.labelOne")} />
          )}
          {showRioTab && <Tab label={t("pages.patientSearch.tabs.labelTwo")} />}
        </Tabs>
      )}
      <Box sx={{ padding: "1rem" }}>
        {selectedTab === 0 &&
          (patientCreateState !== null ? (
            <PatientCreateEdit
              mode={"create"}
              initialState={{
                givenName: patientCreateState.givenName ?? "",
                familyNameAndAddress: {
                  name: patientCreateState.familyName ?? "",
                  address: "",
                  postalCode: "",
                  isConfirmed: false,
                },
                isPatientDateOfBirth: patientCreateState.dateOfBirth !== null,
                dateOfBirth: patientCreateState.dateOfBirth,
              }}
              exitPatientCreate={exitPatientCreate}
            />
          ) : (
            <PatientSearch setPatientCreateState={setPatientCreateState} />
          ))}
        {selectedTab === 1 && <SearchByNhsNumber />}
      </Box>
    </>
  );
}

export function PatientCreatePage({}: DefaultPageProps) {
  const [patientCreateState, setPatientCreateState] = useState<null>(null);
  const navigate = useNavigate();
  return (
    <PatientCreateEdit
      mode={"create"}
      initialState={patientCreateState}
      exitPatientCreate={() => {
        setPatientCreateState(null);
        return navigate(routeFns.patientSearch());
      }}
    />
  );
}

export function SearchReasoningDialog({
  closeSearchReasoningDialog,
  selectedId,
}: {
  closeSearchReasoningDialog: () => void;
  selectedId: string | null;
}) {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);
  const [reasonValueDropdown, setReasonValueDropdown] = useState(null);
  const { t } = useTranslation();

  const [reasonDescription, setReasonDescription] = useState<
    string | undefined
  >(undefined);

  const reasonValues = [
    {
      label: t(
        "pages.patientSearch.searchReasons.searchReasonsValues.emergencyOnCall",
      ),
      value: "emergencyOnCall",
    },
    {
      label: t(
        "pages.patientSearch.searchReasons.searchReasonsValues.adminAddDetails",
      ),
      value: "adminAddDetails",
    },
    {
      label: t(
        "pages.patientSearch.searchReasons.searchReasonsValues.referral",
      ),
      value: "referral",
    },
    {
      label: t(
        "pages.patientSearch.searchReasons.searchReasonsValues.newEpisodeOfCare",
      ),
      value: "newEpisodeOfCare",
    },
    {
      label: t(
        "pages.patientSearch.searchReasons.searchReasonsValues.auditInvestigationComplaint",
      ),
      value: "auditInvestigationComplaint",
    },
    {
      label: t(
        "pages.patientSearch.searchReasons.searchReasonsValues.technicalSupport",
      ),
      value: "technicalSupport",
    },
    {
      label: t("pages.patientSearch.searchReasons.searchReasonsValues.other"),
      value: "other",
    },
  ];
  return (
    <PopupDialog open={true} onClose={closeSearchReasoningDialog}>
      <PopupDialogTitle
        titleText={t("pages.patientSearch.searchReasons.popupDialogTitle")}
        closeDialog={closeSearchReasoningDialog}
      />
      <Banner
        bannerType={BannerList.INFO}
        body={[t("pages.patientSearch.searchReasons.popupDialogBody")]}
      />
      <Box sx={{ my: 2 }}>
        <Dropdown
          label={t("pages.patientSearch.searchReasons.dropdownLabel")}
          selectedValue={reasonValueDropdown}
          name={"invite"}
          values={reasonValues}
          onChange={(e) => {
            setReasonValueDropdown(e);
          }}
        />
        {reasonValueDropdown === "other" && (
          <TextField
            name={"reasonDescription"}
            label={t(
              "pages.patientSearch.searchReasons.otherReasonTextfieldLabel",
            )}
            onChange={(e) => setReasonDescription(e)}
            value={reasonDescription || ""}
            useFullWidth={true}
          />
        )}

        {submitError && (
          <Box sx={{ mb: 4 }}>
            <Banner title={submitError} bannerType={BannerList.ERROR} />
          </Box>
        )}
      </Box>

      <FormFooterSection
        customFooterBackgroundColor="common.white"
        saveLabel={t("buttonLabels.confirm")}
        discardLabel={t("buttonLabels.close")}
        disableSubmit={
          !reasonValueDropdown ||
          (reasonValueDropdown === "other" && !reasonDescription)
        }
        onSave={async () => {
          if (!selectedId)
            return renderErrorToast({
              message: t(
                "pages.patientSearch.searchReasons.errorStates.noPatientSelected",
              ),
            });

          const reasonDropDownLabel = reasonValues.find(
            (v) => v.value === reasonValueDropdown,
          )?.label;

          if (!reasonDropDownLabel)
            return setSubmitError(
              t("pages.patientSearch.searchReasons.errorStates.selectAReason"),
            );

          if (reasonValueDropdown === "other" && !reasonDescription)
            return setSubmitError(
              t(
                "pages.patientSearch.searchReasons.errorStates.provideReasonDescription",
              ),
            );

          const result = await api.patients.addSearchReasoning({
            reason: reasonDropDownLabel,
            reasonDescription: reasonDescription,
            selectedPatientId: selectedId,
          });
          if (result.status === 204) {
            navigate(routeFns.patientHome(selectedId));
          } else {
            renderErrorToast({
              message: `Failed to add a reasoning ${
                (result.data as any).reason
              }`,
            });
          }
        }}
        onCancel={() => {
          closeSearchReasoningDialog();
        }}
      />
    </PopupDialog>
  );
}

const SearchByNhsNumber = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = React.useContext(LoggedInUserContext);
  const rioInstance =
    userContext?.user?.sessionOrganisationConfiguration
      ?.rioInstancesEnabled?.[0];

  const onSearch = useCallback(
    async (nhsNumber: string) => {
      if (!rioInstance) {
        return;
      }

      // Ensure that Yup enforces the rules and transforms
      const cast = rioPatientSearchSchema.cast({ nhsNumber });

      // Create a claimed click-through token
      const token = v4();
      const tokenResponse = await api.rio.clickThrough.createAndClaimToken({
        token,
        externalSystemType: "rio",
        externalSystemId: rioInstance.instanceId,
        externalPatientIdType: "nhs.number",
        externalPatientIdValue: cast.nhsNumber,
      });

      if (tokenResponse.status === 204) {
        // Redirect to the click-through flow to redeem the token and continue the journey
        navigate(`/patients/context-launch?token=${token}`);
      } else {
        // Redirect to a 404 page if the token creation fails
        navigate(`/404`);
      }
    },
    [navigate, rioInstance],
  );

  if (!rioInstance) return null;

  return (
    <Box>
      <FormTitle
        titleText={`Search Rio - ${rioInstance.displayName}`}
        useReducedTopPadding={true}
        hasTitleBottomMargin={false}
      />
      <HorizontalLine />
      <Formik<RioPatientSearchSchema>
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={rioPatientSearchSchema}
        initialValues={rioPatientSearchSchema.getDefault()}
        onSubmit={({ nhsNumber }) => {
          onSearch(nhsNumber);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleBlur,
          setValues,
          submitForm,
          isSubmitting,
        }) => {
          const fieldProps: FieldProps<RioPatientSearchSchema> = {
            validationSchema: rioPatientSearchSchema,
            context: {},
            values,
            errors,
            handleBlur,
            setFieldTouched,
            touched,
            setValues,
          };

          return (
            <form
              // This handler is required for (at least) dev builds of the frontend on Firefox
              // Please test there before removing.
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <Container>
                <TextboxFormField
                  field={{
                    type: "textbox",
                    field: "nhsNumber",
                    label: t("pages.patientSearch.textFieldNhsLabel"),
                  }}
                  fieldProps={fieldProps}
                />
                <HelperText
                  subtext={t("pages.patientSearch.textFieldNhsInfo")}
                />
              </Container>
              <FormFooterSection
                customFooterBackgroundColor="white"
                saveLabel="Search"
                saveVariant="contained"
                performSaveActionOnFormSubmit
                onSave={submitForm}
                disableSubmit={isSubmitting}
                hideDiscard={true}
                onCancel={() => {}}
              />
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
